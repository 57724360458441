/*
* 업무구분: 고객등록
* 화 면 명: MSPCM016D
* 화면설명: 세대원 입력 (세대원카드)
* 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
* 작 성 일: 2022.06.30
* 작 성 자: 이태훈
*/
<template>
  <mo-list-item ref="listItem" expandable prevent-expand preventScrollWhenExpand class="ns-input-accdian">
    <div class="list-item__contents">
      <div class="list-item__contents__title">
        <!-- 고객명 -->
        <mo-text-field ref="custNmRef" :rules="validateRuleName" v-model="hoshInfoListInqrVO.custNm" underline :readonly="disabledObj.custNmdDisabled" placeholder="이름을 입력해주세요."
          maxlength="30" :clearable="!disabledObj.custNmdDisabled"  @focus="fn_ItemFocus" @blur="fn_CheckCustNm" lazy/>
        <mo-button class="ns-btn-round small form-text-field__right-btn" @click="fn_DelHosh">동의제외</mo-button>
      </div>
    </div>
    <template v-slot:expand class="ns-gray-box">
      <div class="list-item__contents">
        <!-- 관계-->
        <msp-bottom-select :items="relationItems" v-model="fn_relation" class="ns-dropdown-sheet2 mb15" placeholder="선택" @toggle-floating="fn_ToggleFloating"/>
        <!-- 외국인 구분-->
        <mo-checkbox v-model="fn_IsForeigner" :disabled="disabledObj.chkForeignerDisabled" class="ns-check">외국인</mo-checkbox>
        <!-- 주민등록번호 -->
        <div class="verify-birth mt40">
          <!-- 주민번호 앞자리 -->
          <mo-text-field v-if="isForeigner" v-model="fn_KnbFrno" placeholder="외국인등록번호" ref="knbFrno" :rules="validateRuleKnbFrno" underline class="birth-input1 form-input-name"  :readonly="disabledObj.knbFrnoDisabled"
            type="number" @keyup="fn_KnbFrnoFocusType($event)" mask="######" lazy/>
          <mo-text-field v-else v-model="fn_KnbFrno" placeholder="생년월일" ref="knbFrno" :rules="validateRuleKnbFrno" underline class="birth-input1 form-input-name" :readonly="disabledObj.knbFrnoDisabled"
            type="number" @keyup="fn_KnbFrnoFocusType($event)" mask="######" lazy/>
          <span>-</span>

          <!-- 주민등록번호 뒷번호가 입력되어 있을 경우 -->
          <div v-if="disabledObj.knbBknoDisabled">
            <em>{{hoshInfoListInqrVO.knbBkFstNo}}●●●●●●</em>
          </div>

          <!-- 주민등록번호 뒷번호가 입력되지 않을 경우 -->
          <div v-else>
            <!-- 주민번호 뒷자리 (성별) -->
            <mo-text-field ref="knbBkno" v-if="!selcAgreeTypeMcnst" v-model="hoshInfoListInqrVO.knbBkFstNo" underline class="birth-input2 form-input-name" 
              placeholder="0" type="number" mask="#" @blur="fn_ChildFocusOut" :rules="validateRuleKnbBkno" lazy/>
            <em v-if="!selcAgreeTypeMcnst">●●●●●●</em>
            <!-- PC전용 : 뒷자리-->
            <mo-text-field ref="knbBkno" v-else-if="isForeigner && selcAgreeTypeMcnst && !$commonUtil.isMobile()" v-model="fn_KnbBkno"
            underline class="birth-input3" placeholder="외국인등록번호 뒷자리" type="number" mask="#######" :rules="validateRuleKnbBkno" lazy/>
            <mo-text-field ref="knbBkno" v-else-if="!isForeigner && selcAgreeTypeMcnst && !$commonUtil.isMobile()" v-model="fn_KnbBkno"
            underline class="birth-input3" placeholder="주민번호 뒷자리" type="number" mask="#######" :rules="validateRuleKnbBkno" lazy/>
            <!-- mobile전용 : 뒷자리 (보안키 변경 필요)-->
            <m-trans-key-input ref="mkKnbBkno" v-else-if="isForeigner && selcAgreeTypeMcnst && $commonUtil.isMobile()" class="birth-input3 ns-certify-key"
              placeholder="외국인등록번호 뒷자리" v-model="fn_KnbBkno" :type="'numberMax7'" :start="'1'" :end="'-1'" @mask-type="fn_getMaskType"/>
            <m-trans-key-input ref="mkKnbBkno" v-else class="birth-input3 ns-certify-key"
              placeholder="주민번호 뒷자리" v-model="fn_KnbBkno" :type="'numberMax7'" :start="'1'" :end="'-1'" @mask-type="fn_getMaskType"/>
          </div>
        </div>
        <!-- 고객조회 버튼-->
        <!-- 필컨 선택, 세대원이 기존 고객이여도 '주민번호 확보'가 안되어 있을 시, 고객카드조회 버튼 활성화 : 2022-08-09 -->
        <mo-button class="ns-btn-round mt20" v-if="isNew || !disabledObj.knbBknoDisabled"
          :disabled="hoshInfoListInqrVO.searchFlag !== '' && disabledObj.knbBknoDisabled" 
          @click="fn_ChildFocusOut('autoSearch')">고객카드조회</mo-button>
        <!-- 동의유형선택 -->
        <div class="title-box">
          <span class="title mb7">동의유형선택</span>
          <mo-checkbox class="ns-check" checked v-if="selcAgreeTypeMcnst" readonly>필수컨설팅</mo-checkbox>
          <mo-checkbox class="ns-check" checked v-if="selcAgreeTypeMkt" readonly>마케팅</mo-checkbox>
        </div>
        <!-- 직업 -->
        <div class="title-box mb20">
          <span class="title">직업</span>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column">
            <div class="ns-radio-list">
              <mo-radio v-for="item in childJobs" v-model="fn_JobMain" :key="item.value" :value="item.value">{{item.text}}</mo-radio>
            </div>
          </ur-box-container>
        </div>
        <mo-text-field v-model="fn_JobText" searchable underline readonly placeholder="다른 직업 찾기" @click="fn_BtnS3OnClick" @click-icon="fn_BtnS3OnClick"/>
        <mo-button class="ns-btn-round mt20" @click="fn_BtnCntryCodeClick" v-if="isForeigner">외국인 정보 입력</mo-button>
      </div>
    </template>

  </mo-list-item>
</template>

<script>
/***********************************************************************************
* 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import moment from 'moment'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPCM013P from '@/ui/cm/MSPCM013P'
import MSPCM015P from '@/ui/cm/MSPCM015P' // 외국인 등록정보
import MSPCM019P from '@/ui/cm/MSPCM019P' // 신분증진위여부
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM016P",
    screenId: "MSPCM016P",
    components: {
      moment,
      MspBottomSelect,
      MSPBottomAlert,
      MSPCM013P,
      MSPCM015P,
      MSPCM019P
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    props: {
      hoshInfoListInqrVO: {
        type: Object,
        default : null
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      let lv_Vm = this
      // 오늘 날짜 조회
      lv_Vm.$commonUtil.getServerTime().then(function (response) {
        lv_Vm.today = moment(response).format('YYYYMMDD')

        lv_Vm.selcAgreeTypeMcnst = lv_Vm.hoshInfoListInqrVO.selcAgreeTypeMcnst
        lv_Vm.selcAgreeTypeMkt = lv_Vm.hoshInfoListInqrVO.selcAgreeTypeMkt

        if (lv_Vm.hoshInfoListInqrVO.isNew) { // 신규인경우
          // 주고객의 동의유형 셋팅
          if (lv_Vm.hoshInfoListInqrVO.custJobCd === '') {
            lv_Vm.childJob = lv_Vm.childJobs[0].value
            lv_Vm.hoshInfoListInqrVO.custJobCd = lv_Vm.childJobs[0].value
            lv_Vm.hoshInfoListInqrVO.custJobCdNm = lv_Vm.childJobs[0].text
          }
          lv_Vm.isNew = lv_Vm.hoshInfoListInqrVO.isNew
        }

        if (lv_Vm.hoshInfoListInqrVO.knbScCd === 'ZPER02') { // 외국인인 경우 외국인 체크박스 클릭 처리
          lv_Vm.isForeigner = true
          lv_Vm.chkRrn = false // 미확보 버튼은 uncheck 되어야 함
        } else {
          lv_Vm.isForeigner = false
        }

        // 태아인 경우 태아 데이터 처리하는 함수 호출
        if (lv_Vm.hoshInfoListInqrVO.custNm === '태아') {
          lv_Vm.chkFetusCust = true
          lv_Vm.fn_CheckFetusCust(true)
        }

        lv_Vm.custNmLb = 'hoshInfoListInqrVO.custNm_' + lv_Vm.hoshInfoListInqrVO.chnlCustId // 이름 label의 ref이름 지정

        lv_Vm.agreeTypeMcnst = lv_Vm.hoshInfoListInqrVO.agreeTypeMcnst
        lv_Vm.agreeTypeMkt = lv_Vm.hoshInfoListInqrVO.agreeTypeMkt
        lv_Vm.hoshInfoListInqrVO.celnoBkno = ''
        lv_Vm.fn_BindHoshInfo()
      })
    },
    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      this.$bizUtil.insSrnLog('MSPCM016D')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        stndKeyList: [],
        custNmLb: '',        
        today: '',
        isNew: false, // 신규 세대원 카드 여부 - 신규 세대원 카드면 true로 변경
        msgDelHoshConfirm: '선택한 세대원을 제외할까요?<br>(해당 세대원의 동의만 진행되지않을뿐<br>고객카드는 삭제되지 않습니다)', // '삭제하시겠습니까?'
        agreeTypeMcnst: false, // 필수컨설팅 체크
        agreeTypeMkt: false, // 마케팅동의 체크
        selcAgreeTypeMcnst: false, // 필수컨설팅동의 선택여부
        selcAgreeTypeMkt: false, // 마케팅동의 선택여부
        isForeigner: true, // 외국인 여부
        isKnbBknoModified: false,
        chkFetusCust: false, // 태아 여부
        custNmResult: true, // 이름 유효성 체크 결과
        custNmErrorMsg: '', // 이름 유효성 결과 에러 메시지
        chkKnbResult: true, // 주민번호 유효성 검증 결과
        knbErrorMsg: '', // 주민번호 유효성 체크 후 에러 메시지
        chkRrn: false,
        // 기존 세대원 리스트
        existHoshInfoListInqrVO: [],
        // 신규 세대원 리스트
        newHoshInfoListInqrVO: [],
        // 기존 세대원 저장용 리스트
        saveExistHoshInfoListInqrVO: [],
        // 신규 세대원 저장용 리스트
        saveNewHoshInfoListInqrVO: [],
        hoshInfoVO: {
          customerType: 'new', // 주고객이 기존, 신규인지 여부
          selcAgreeTypeMkt: '', // 주고객 마케팅 동의여부
          selcAgreeTypeMcnst: '', // 주고객 필컨 동의여부
          cnsltNo: '', // 컨설턴트번호
          chnlCustId: '', // 채널 고객ID
          trtrEno: '', // 처리자사번
          chnlCstgrId: '', // 채널고객그룹ID
          chnlCustScCd: '', // 가망 임시 구분
          agreeTypeMcnst: false, // 필수컨설팅 체크
          agreeTypeMkt: false, // 마케팅동의 체크
          isCelnoChanged: false,
          celno: '', // 휴대전화
          prcsfCd: 'I', // I:신규, U:업데이트
          celnoFrno: {}, // 휴대전화 앞자리
          celnoBkno: '', // 휴대전화 뒷자리
          isCustJobCdChanged: false,
          custJobCd: '', // 직업코드
          custJobCdNm: '', // 직업명
          custNm: '', // 이름
          custNmPrev: '', // 원본 이름(이름명 변경시 체크)
          scrapYn: '', // 스크래핑진위확인 여부
          custEngNm: '', // 고객 영문명
          natyCd: '', // 국가코드
          natyNm: '', // 국가코드명
          psprtNo: '', // 여권번호
          dmclScCd: '', // 거주지구분코드
          dmclScCdNm: '', // 거주지구분코드명
          foreignPartnerNo: '', // 외국인고객번호
          bpCustEngNm: '', // BP 영문명
          bpPsprtNo: '', // BP 여권번호
          bpNatyCd: '', // BP 국적코드
          bpNatyNm: '', // BP 국적명
          bpDmclScCd: '', // BP 거주지구분코드
          bpDmclScCdNm: '', // BP 거주지구분코드명
          isCustNmChanged: false, // 고객명 변경 여부
          knb: '', // 전체주민번호
          knbBackup: '', // 전체주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
          knbFrno: '', // 주민번호 앞자리
          knbBkno: '', // 주민번호 뒷자리
          knbBknoCipher: '', // 주민번호 뒷자리 암호화
          knbBknoCipherBackup: '', // 주민번호 뒷자리 백업(암호화 값이 들어올 경우에만 임시 보관장소로 쓰임)
          knbBkFstNo: '', // 주민번호 뒷자리 성별구분코드
          isKnbChanged: false, // 주민번호 변경 여부
          sxdsCd: '', // 성별
          dob: '', // 생년월일
          knbScCd: 'ZPER01', // 국적구분코드 (ZPER01: 내국인, ZPER02: 외국인)
          unAprvCustYn: 'Y', // 미승인 여부 Y:미승인, N: 승인,
          mnCustRltnCd: '03', // 주고객관계코드
          ralBrthd: '', // 실제 생년월일
          samScCd: '', // 음력,양력 구분
          rlnmCnfRsltCd: '', // 실명확인결과코드
          hoshMnYn: '', // 세대주구분
          mcnstYmd: '', // 필수 컨설팅 만료일
          mktYmd: '', // 마케팅 만료일,
          isPossibleFetus: true, // 태아 추가 가능 여부
          isNew: true, // 신규 세대원인지 여부
          searchFlag: '', // 고객카드조회 여부
          isDupCust: false, // 고객카드중복여부
          isMobile: 'Y', // 모바일 사용여부
          iCCustInfoPrcusCnsntVO: {}, // 정보활용동의 내역 객체
        },
        disabledObj: {
          custNmdDisabled: false,
          chkForeignerDisabled: false,
          mcnstDisabled: false,
          mktDisabled: false,
          knbFrnoDisabled: false,
          knbBknoDisabled: false,
          addrTypeDisabled: false,
          zipBtnDisabled: false,
          entryDisabled: false,
          celnoFrnoDisabled: false,
          celnoBknoDisabled: false,
          emailDisabled: false,
          emailBknoDisabled: false,
          jobDisabled: false,
          nextBtnDisabled: false // 다음버튼 비활성화 여부
        },
        // 신규 세대원 추가시 임시 고객 채널 ID생성
        newChannelId: 0,

        relationProvider: '',  
        /* 공통 코드*/
        relationItems: [
          { value: '03', text: '자녀'},
          { value: '04', text: '손자'},
          { value: '08', text: '기타'}
          ],
          childJobs: [
          {
            value: '116100',
            text: '미취학아동'
          },
          {
            value: '112200',
            text: '초,중,고등학생'
          }
        ],
        childJob: '',
      
        /*팝업 연동*/
        popup013: {}, // MSPCM013P 보험직업조회 팝업
        popup019: {},
        encKey: '',
        validateRuleName: [ // 고객명 Rule 체크
          v => !!v || ' ',
        ],
        validateRuleKnbFrno: [ // 주민번호 앞자리 Rule 체크
          v => !!v || ' ',
          v => v.length === 6 || ' '
        ],
        validateRuleKnbBkno: [ // 주민번호 뒷자리 Rule 체크
          v => !!v || ' '
        ],
      } //return end
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: { 
      fn_relation: { // 주고객관계 콤보
        get: function () {
          return this.relationProvider
        },
        set: function (data) {
          this.hoshInfoListInqrVO.mnCustRltnCd = data
          this.relationProvider = data
        }
      },
      fn_IsForeigner: { // 외국인 체크박스
        get: function () {
          return this.isForeigner
        },
        set: function (data) {
          this.isForeigner = data

          if (data) {
            this.hoshInfoListInqrVO.knbScCd = 'ZPER02' // 외국인
            this.chkRrn = false
          } else {
            this.hoshInfoListInqrVO.knbScCd = 'ZPER01' // 내국인
          }
        }
      },
      fn_JobMain: { // 직업 버튼 리스트 처리
        get: function () {
          return this.childJob
        },
        set: function (data) {
          if (data.length > 0) {
            if (this.isNew === false && this.hoshInfoListInqrVO.custJobCd !== '' && this.hoshInfoListInqrVO.custJobCd !== data) {
              this.hoshInfoListInqrVO.isCustJobCdChanged = true
            }

            this.childJob = data
            this.hoshInfoListInqrVO.custJobCd = data
            let lv_JobCdNm = this.childJobs.filter(
              (val) => {
              if (val.value === data) {
                return val
              }
            })
          this.hoshInfoListInqrVO.custJobCdNm = lv_JobCdNm[0].text
          } else {
            this.hoshInfoListInqrVO.custJobCd = ''
            this.hoshInfoListInqrVO.custJobCdNm = ''
          }
        }
      },
      fn_JobText: {
        get: function () {
          let lv_Txt = this.hoshInfoListInqrVO.custJobCdNm
          return lv_Txt
        },
        set: function (data) {
          //
        }
      },
      fn_KnbFrno: { // 주민번호 앞자리 변경시 호출 - 전체주민번호변수(hoshInfoListInqrVO.knb)에 변경 값 처리
        get: function () {
          return this.hoshInfoListInqrVO.knbFrno
        },
        set: function (data) {
          let lv_TmpData = this.hoshInfoListInqrVO.knbFrno
          if (this.isNew === false && this.hoshInfoListInqrVO.knbFrno !== '' && this.hoshInfoListInqrVO.knbFrno !== data) {
            this.hoshInfoListInqrVO.isKnbChanged = true
          }
          if (this.hoshInfoListInqrVO.knbFrnoBkUp !== undefined) {
            if (this.hoshInfoListInqrVO.knbFrnoBkUp !== data) {
              this.searchRsltMsg = ''
              this.hoshInfoListInqrVO.isDupCust = false
              this.hoshInfoListInqrVO.searchFlag = ''
              this.existingCustomer = false
            }
          }

          this.hoshInfoListInqrVO.knbFrno = data
          this.hoshInfoListInqrVO.knb = (this.$commonUtil.isMobile()) ? '' : (data + this.hoshInfoListInqrVO.knbBkno) // 모바일은 공백, PC는 평문으로 전체 번호 보관

          //모바일은 생년월일 수정 시, 뒷자리 초기화 되도록 추가
          if(this.$commonUtil.isMobile() && this.$bizUtil.isEmpty(this.hoshInfoListInqrVO.knb)){
            this.fn_KnbBkno = ''
          }

          if (data.length === 6 && this.isKnbBknoModified) {
            // this.fn_CheckSsnValidation() // 주민번호 유효성 체크 (테블릿과 동일하게 맞춤 2023.04.28)
          } else {
            if (this.$commonUtil.isMobile() && this.isNew === true && data.length === 6 &&
                this.hoshInfoListInqrVO.knbFrno !== '' && lv_TmpData !== data && this.hoshInfoListInqrVO.infoPrcusCnsntTypCd !== '19') {
              // 보안 키패드 호출 (추가 필요)
              let lv_Vm = this
              //this.interval = window.setTimeout( () => { // 숫자 키패드 사라지는 시점을 0.5초 후에 하도록 처리 - 바로 사라질 경우 다른 컴포넌트까지 눌러지는 상황을 막기 위해
                //lv_Vm.$refs.socialPostNum.mTransKey('numberMax7', '1', '-1')
                //lv_Vm.$refs['hoshInfoListInqrVO.knbFrno'].$data.isActive = false // 주민번호 앞자리 가상키패드 제거
             // }, 500)
            }

            if (data.length === 6 && this.chkRrn) { // 미확보 체크박스가 선택되어져 있는 경우 임시 주민번호 세팅
              this.fn_SetKnbGender()
            }
          }
        }
      },
      fn_KnbBkno: { // 주민번호 뒷자리 변경시 호출 - 전체주민번호변수(hoshInfoListInqrVO.knb)에 변경 값 처리
        get: function () {
          let lv_KnbBkno = ''
          if (this.$commonUtil.isMobile()) {
            this.hoshInfoListInqrVO.prextKnbScCd = this.$commonUtil.getIsIOS() ? 'IOS' : ''

            if (this.hoshInfoListInqrVO.infoPrcusCnsntTypCd === '19') { // 마동만 선택한 경우 뒷자리 성별코드 + 000000 으로
              lv_KnbBkno = this.hoshInfoListInqrVO.knbBkFstNo + '000000'
            } else {
              lv_KnbBkno = this.hoshInfoListInqrVO.knbBknoCipher
            }
          } else {
            if (this.hoshInfoListInqrVO.infoPrcusCnsntTypCd === '19') { // 마동만 선택한 경우 뒷자리 성별코드 + 000000 으로
              lv_KnbBkno = this.hoshInfoListInqrVO.knbBkFstNo + '000000'
            } else {
              lv_KnbBkno = this.hoshInfoListInqrVO.knbBkno
            }
          }
          return lv_KnbBkno
        },
        set: function (data) {
          if (this.$commonUtil.isMobile() && data !== '') {
            if (this.hoshInfoListInqrVO.knbBknoCipher !== '' && this.hoshInfoListInqrVO.knbBknoCipher !== data) {
              this.hoshInfoListInqrVO.isKnbChanged = true
            }
            if (this.hoshInfoListInqrVO.knbBknoCipherBkUp !== undefined) {
              if (this.hoshInfoListInqrVO.knbBknoCipherBkUp !== data) {
                this.searchRsltMsg = ''
                this.hoshInfoListInqrVO.isDupCust = false
                this.hoshInfoListInqrVO.searchFlag = ''
                this.existingCustomer = false
              }
            }
            this.hoshInfoListInqrVO.knbBknoCipher = data
            // knbBknoCipher는 주민번호 암호화 입력값 외에 화면에서 보여지는 마스킹 값도 같이 사용하는 필드임. 이에따라 암호화 입력값은 별도 보관 처리 후
            // 저장시점에 knbBknoCipherBackup를 knbBknoCipher에 다시 저장하도록 함.
            if ( !this.$bizUtil.isEmpty(data) && data.length > 7 ) {
              this.hoshInfoListInqrVO.knbBknoCipherBackup = data
            }
          } else {
            if (this.hoshInfoListInqrVO.knbBkno !== '' && this.hoshInfoListInqrVO.knbBkno !== data) {
              this.hoshInfoListInqrVO.isKnbChanged = true
            }
            if (this.hoshInfoListInqrVO.knbBknoBkUp !== undefined) {
              if (this.hoshInfoListInqrVO.knbBknoBkUp !== data) {
                this.searchRsltMsg = ''
                this.hoshInfoListInqrVO.isDupCust = false
                this.hoshInfoListInqrVO.searchFlag = ''
                this.existingCustomer = false
              }
            }
          }
          
          if (this.$commonUtil.isMobile()) {
            this.hoshInfoListInqrVO.knbBknoCipher = data           
          } else {
            this.hoshInfoListInqrVO.knbBkno = data
          }
          this.hoshInfoListInqrVO.knb = this.hoshInfoListInqrVO.knbFrno + data
        }
      },
      fn_CheckKnb () { // 주민번호
        let lv_KnbResult = ''
        if (this.chkRrn) { // 주민번호 미확보 체크 선택시에는 주민번호 앞자리만 필수 체크
          if (this.hoshInfoListInqrVO.knbFrno !== '' && this.hoshInfoListInqrVO.knbFrno.length === 6) {
            lv_KnbResult = this.hoshInfoListInqrVO.knbFrno
          }
        } else {
          if (this.hoshInfoListInqrVO.knbFrno !== '' && (this.hoshInfoListInqrVO.knbBkno !== '' || this.hoshInfoListInqrVO.knbBknoCipher !== '') &&
              this.hoshInfoListInqrVO.knbFrno.length === 6) { // 주민번호 확보시에는 앞자리, 뒷자리 모두 입력되었는지 체크
            lv_KnbResult = this.hoshInfoListInqrVO.knbFrno + this.hoshInfoListInqrVO.knbBkno + this.hoshInfoListInqrVO.knbBknoCipher
          }
        }
        return lv_KnbResult
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_CheckCKnbGender
      * 설명       : 주민번호 뒷자리 성별구분코드에 따른 정합성 체크
      ******************************************************************************/
      fn_CheckCKnbGender () {
        let lv_RetVal = true
        let lv_Msg = '유효하지 않은 생년월일 정보입니다. 정확한 정보를 확인하시기 바랍니다.'

        if (this.$bizUtil.isEmpty(this.fn_KnbFrno)) {
          lv_Msg = '생년월일을 입력해주세요.'
          this.hoshInfoListInqrVO.chkKnbResult = false
          lv_RetVal = false
          this.getStore('confirm').dispatch('ADD', lv_Msg)
          return lv_RetVal
        } else if (this.$bizUtil.isEmpty(this.fn_KnbBkno)) {
          lv_Msg = '필수컨설팅 동의고객은 주민번호 뒷자리 필수 입력입니다.'
          this.hoshInfoListInqrVO.chkKnbResult = false
          lv_RetVal = false
          this.getStore('confirm').dispatch('ADD', lv_Msg)
          return lv_RetVal
        }

        // 주민번호 뒷자리 처리
        let lv_FrontYear = this.$bizUtil.addBirthFrontYear(this.hoshInfoListInqrVO.knbFrno).substring(0, 2) // 19, 20 조회

        if (this.hoshInfoListInqrVO.knbFrno !== null && this.hoshInfoListInqrVO.knbFrno !== '') {
          if (lv_FrontYear === '19') {
            if (this.isForeigner === true) {
              if (this.hoshInfoListInqrVO.knbBkFstNo !== '5' && this.hoshInfoListInqrVO.knbBkFstNo !== '6') {
                this.hoshInfoListInqrVO.chkKnbResult = false
                lv_RetVal = false
                this.getStore('confirm').dispatch('ADD', lv_Msg)
              } else {
                this.hoshInfoListInqrVO.chkKnbResult = true
              }
            } else {
              if (this.hoshInfoListInqrVO.knbBkFstNo !== '1' && this.hoshInfoListInqrVO.knbBkFstNo !== '2') {
                this.hoshInfoListInqrVO.chkKnbResult = false
                lv_RetVal = false
                this.getStore('confirm').dispatch('ADD', lv_Msg)
              } else {
                this.hoshInfoListInqrVO.chkKnbResult = true
              }
            }
          } else if (lv_FrontYear === '20') {
            if (this.isForeigner === true) {
              if (this.hoshInfoListInqrVO.knbBkFstNo !== '7' && this.hoshInfoListInqrVO.knbBkFstNo !== '8') {
                this.hoshInfoListInqrVO.chkKnbResult = false
                lv_RetVal = false
                this.getStore('confirm').dispatch('ADD', lv_Msg)
              } else {
                this.hoshInfoListInqrVO.chkKnbResult = true
              }
            } else {
              if (this.hoshInfoListInqrVO.knbBkFstNo !== '3' && this.hoshInfoListInqrVO.knbBkFstNo !== '4') {
                this.hoshInfoListInqrVO.chkKnbResult = false
                lv_RetVal = false
                this.getStore('confirm').dispatch('ADD', lv_Msg)
              } else {
                this.hoshInfoListInqrVO.chkKnbResult = true
              }
            }
          }
        }
        return lv_RetVal
      },
      /******************************************************************************
      * Function명 : fn_SetKnbGender
      * 설명       : 미확인 체크 선택 후, 남/여 버튼 선택 시 임시 주민번호 세팅
      ******************************************************************************/
      fn_SetKnbGender () {
        // 주민번호 뒷자리 처리
        let lv_FrontYear = this.$bizUtil.addBirthFrontYear(this.hoshInfoListInqrVO.knbFrno).substring(0, 2) // 19, 20 조회
        if (this.gender[0].key === 'male') { // 남자 선택 시
          if (lv_FrontYear === '19') {
            if (this.$commonUtil.isMobile()) {
              this.hoshInfoListInqrVO.knbBknoCipher = '1000000'
              this.hoshInfoListInqrVO.knbBknoCipherBackup = ''
            } else {
              this.hoshInfoListInqrVO.knbBkno = '1000000'
            }
          } else {
            if (this.$commonUtil.isMobile()) {
              this.hoshInfoListInqrVO.knbBknoCipher = '3000000'
              this.hoshInfoListInqrVO.knbBknoCipherBackup = ''
            } else {
              this.hoshInfoListInqrVO.knbBkno = '3000000'
            }
          }
        } else { // 여자 선택 시
          if (lv_FrontYear === '19') {
            if (this.$commonUtil.isMobile()) {
              this.hoshInfoListInqrVO.knbBknoCipher = '2000000'
              this.hoshInfoListInqrVO.knbBknoCipherBackup = ''
            } else {
              this.hoshInfoListInqrVO.knbBkno = '2000000'
            }
          } else {
            if (this.$commonUtil.isMobile()) {
              this.hoshInfoListInqrVO.knbBknoCipher = '4000000'
              this.hoshInfoListInqrVO.knbBknoCipherBackup = ''
            } else {
              this.hoshInfoListInqrVO.knbBkno = '4000000'
            }
          }
        }

        if (this.$commonUtil.isMobile()) {
          this.hoshInfoListInqrVO.knb = this.hoshInfoListInqrVO.knbFrno + this.hoshInfoListInqrVO.knbBknoCipher // 주민번호 재조합
          this.hoshInfoListInqrVO.knbBackup = this.hoshInfoListInqrVO.knbFrno + this.hoshInfoListInqrVO.knbBknoCipher
        } else {
          this.hoshInfoListInqrVO.knb = this.hoshInfoListInqrVO.knbFrno + this.hoshInfoListInqrVO.knbBkno // 주민번호 재조합
          this.hoshInfoListInqrVO.knbBackup = this.hoshInfoListInqrVO.knbFrno + this.hoshInfoListInqrVO.knbBkno
        }
        // this.fn_CheckAgreePossible() // 만 19세 이상인지 여부 체크
      },
      /******************************************************************************
      * Function명 : fn_CheckFetusCust
      * 설명       : 태아여부 체크박스 선택 시 호출 - true 인 경우 기본값 세팅
      ******************************************************************************/
      fn_CheckFetusCust (data) {
        if (data) { // 체크 true인 경우
          this.hoshInfoListInqrVO.custNm = '태아'
          this.hoshInfoListInqrVO.knbFrno = '000000' // 주민번호 앞자리
          if (this.$commonUtil.isMobile()) {
            this.hoshInfoListInqrVO.knbBknoCipher = '3******'
            if (this.$refs.socialPostNum !== undefined && this.$refs.socialPostNum !== null) { // created 시점에서 호출할 경우에는 객체 생성전임
              this.$refs.socialPostNum.$data.txtField = this.hoshInfoListInqrVO.knbBknoCipher
            }
          } else {
            this.hoshInfoListInqrVO.knbBkno = '3******' // 주민번호 뒷자리
          }
          this.hoshInfoListInqrVO.knb = '0000003******' // 주민번호 전체
          this.hoshInfoListInqrVO.knbBackup = '0000003000000' // 주민번호 전체
          this.hoshInfoListInqrVO.celnoFrno = this.phoneItems[0] // 휴대전화 번호 앞자리
          this.hoshInfoListInqrVO.celno = ''
          this.hoshInfoListInqrVO.celnoBkno = ''
          
          this.childJob = this.childJobs[0].value
          this.hoshInfoListInqrVO.custJobCd = this.childJobs[0].value
          this.hoshInfoListInqrVO.custJobCdNm = this.childJobs[0].text
          this.hoshInfoListInqrVO.mnCustRltnCd = '03'
          this.agreeTypeMcnst = this.hoshInfoListInqrVO.agreeTypeMcnst = false
          this.agreeTypeMkt = this.hoshInfoListInqrVO.agreeTypeMkt = false
          this.chkRrn = false

          // 주민번호 유효성 초기화
          this.chkKnbResult = true

          // 이름 유효성 체크 초기화
          this.custNmResult = true
          this.custNmErrorMsg = ''

          // 컴포넌트 비활성화 처리
          this.disabledObj.custNmdDisabled = this.disabledObj.mcnstDisabled = this.disabledObj.mktDisabled = this.disabledObj.knbFrnoDisabled =
          this.disabledObj.knbBknoDisabled = this.disabledObj.celnoFrnoDisabled = this.disabledObj.celnoBknoDisabled = this.disabledObj.jobMainDisabled =
          this.disabledObj.jobNmDisabled = this.disabledObj.chkForeignerDisabled = true
        } else {
          this.hoshInfoListInqrVO.custNm = ''
          this.hoshInfoListInqrVO.knbFrno = '' // 주민번호 앞자리
          this.hoshInfoListInqrVO.knbBkno = '' // 주민번호 뒷자리
          if (this.$commonUtil.isMobile()) {
            this.hoshInfoListInqrVO.knbBknoCipher = ''
            if (this.$refs.socialPostNum !== undefined && this.$refs.socialPostNum !== null) { // created 시점에서 호출할 경우에는 객체 생성전임
              this.$refs.socialPostNum.$data.txtField = ''
            }
          } else {
            this.hoshInfoListInqrVO.knbBkno = '' // 주민번호 뒷자리
          }
          this.hoshInfoListInqrVO.knb = '' // 주민번호 전체
          this.hoshInfoListInqrVO.knbBackup = '' // 주민번호 전체
         // this.hoshInfoListInqrVO.celnoFrno = this.phoneItems[1] // 휴대전화 번호 앞자리
          this.childJob = this.childJobs[0].value
          this.hoshInfoListInqrVO.custJobCd = this.childJob[0].value
          this.hoshInfoListInqrVO.custJobCdNm = this.childJob[0].text

          this.hoshInfoListInqrVO.mnCustRltnCd = '03'

          // 컴포넌트 활성화 처리
          this.disabledObj.custNmdDisabled = this.disabledObj.mcnstDisabled = this.disabledObj.mktDisabled = this.disabledObj.knbFrnoDisabled =
          this.disabledObj.knbBknoDisabled = this.disabledObj.celnoFrnoDisabled = this.disabledObj.celnoBknoDisabled = this.disabledObj.jobMainDisabled =
          this.disabledObj.jobNmDisabled = this.disabledObj.chkForeignerDisabled = false
        }
      },
      /******************************************************************************
      * Function명 : fn_BindHoshInfo
      * 설명       : 메인화면에서 넘어온 세대원 정보 VO 매핑
      ******************************************************************************/
      fn_BindHoshInfo () {
        /************************************************************************************
         * ----------- 주고객관계코드 세팅 start -----------
         ************************************************************************************/
        if (this.hoshInfoListInqrVO.mnCustRltnCd !== undefined && this.hoshInfoListInqrVO.mnCustRltnCd.trim() !== '') {
          let lv_MnCustRltnCd = this.hoshInfoListInqrVO.mnCustRltnCd
          let lv_MnCustRltnCdNm = this.hoshInfoListInqrVO.mnCustRltnCdNm
          // 조회한 주고객관계코드 값이 있는지 체크함. 없는 경우 tmpCelnoFrnoObj 빈 객체임.
          let tmpMnCustRltnCdObj = this.relationItems.filter(
            (val) => {
              if (val.value === lv_MnCustRltnCd) {
                return val
              }
            }
          )
          // 주고객관계코드
          if (tmpMnCustRltnCdObj.length > 0) {
            this.relationProvider = tmpMnCustRltnCdObj[0].value
          } else {
            this.relationProvider = lv_MnCustRltnCd
          }
        }
        /************************************************************************************
         * ----------- 주고객관계코드 세팅 end -----------
         ************************************************************************************/
        /************************************************************************************
         * ----------- 직업 세팅 start -----------
         ************************************************************************************/
        if (this.hoshInfoListInqrVO.custJobCd !== undefined && this.hoshInfoListInqrVO.custJobCd.trim() !== '') {
          // 직업 버튼 처리
          let lv_Vm = this
          let tmpJobMain = lv_Vm.childJobs.filter(
            (val) => {
              if (val.value === lv_Vm.hoshInfoListInqrVO.custJobCd) {
                return val
              }
            }
          )

          if (tmpJobMain.length > 0) { // 매핑되는 값이 있는 경우
            this.childJob = tmpJobMain[0].value
          }
        } else {
          if (!this.hoshInfoListInqrVO.isNew && this.hoshInfoListInqrVO.jobCd.trim() === '') { // 기존 세대원의 직업코드가 없으면 초기화 함.
            this.childJob = ''
          }
        }
        /************************************************************************************
         * ----------- 직업 세팅 end -----------
         ************************************************************************************/

        // 기존 세대원 고객일 때 컴포넌트 비활성화 처리
        if (!this.isNew) {
          if (this.selcAgreeTypeMcnst && this.hoshInfoListInqrVO.chnlCustScCd === '02') { // 필컨에 주민번호 미확보 시 수정 가능하도록 활성화
            this.disabledObj.custNmdDisabled = true 
            //this.disabledObj.custNmdDisabled = false
            //this.hoshInfoListInqrVO.custNmPrev = this.hoshInfoListInqrVO.custNm // 고객명이 변경되었는지 여부 체크를 위해 원본 고객명을 저장
          } else { // 보유 계약인 경우 세대원 이름 수정 불가
            this.disabledObj.custNmdDisabled = true
          }

          this.disabledObj.knbFrnoDisabled = this.disabledObj.knbBknoDisabled = this.disabledObj.chkForeignerDisabled = true
          if (this.hoshInfoListInqrVO.chnlCustScCd === '02' && this.hoshInfoListInqrVO.searchFlag !== '') { 
            this.disabledObj.knbBknoDisabled = false // 임시고객인 경우 뒷자리 수정가능
            this.$refs['listItem'].isExpand = true
          } else {
            if (this.hoshInfoListInqrVO.isKnbChanged) { // 뒷자리가 수정된 상태이면 뒷자리 수정 가능하도록 처리
              // this.disabledObj.knbBknoDisabled = false // 한번 조회가 완료 되면 뒷자리 수정 못하도록 수정 되어 주석처리 2022-08-09
            }
          }
          if (this.hoshInfoListInqrVO.custNm === '태아') { // 태아는 수정 불가 모두 비활성화
            // 컴포넌트 활성화 처리
            this.disabledObj.custNmdDisabled = this.disabledObj.mcnstDisabled = this.disabledObj.mktDisabled = this.disabledObj.knbFrnoDisabled =
            this.disabledObj.knbBknoDisabled = this.disabledObj.celnoFrnoDisabled = this.disabledObj.celnoBknoDisabled = this.disabledObj.jobMainDisabled =
            this.disabledObj.jobNmDisabled = this.disabledObj.chkForeignerDisabled = true
          }
        } else { // 신규일때
          if (this.hoshInfoListInqrVO.infoPrcusCnsntTypCd === '19') { // 마동만 선택한 경우 외국인 입력불가
            this.disabledObj.chkForeignerDisabled = true
          }

          if (this.hoshInfoListInqrVO.searchFlag !== '') { // 고객조회가 정상으로 되었을 경우, 수정 불가 처리 2022-08-01
            this.fn_SearchedDisabled()
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_DelHosh
      * 설명       : 삭제할지 confirm창 호출
      ******************************************************************************/
      fn_DelHosh () {
        let alertMsg = this.msgDelHoshConfirm
        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            //single: true,
            title: "",
            content: alertMsg, //삭제하시겠습니까?
            title_pos_btn: "예",
            title_neg_btn: "아니요"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              this.fn_DelHoshConfirm()
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_AlertPop)
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_DelHoshConfirm
      * 설명       : Confirm창의 '예' 클릭 시 호출. 부모 페이지의 'delHosh' 이벤트 호출. 파라미터로 임시로 생성한 채널 고객 ID 넘겨줌
      ******************************************************************************/
      fn_DelHoshConfirm () {
        this.$emit('delHosh', this.hoshInfoListInqrVO.chnlCustId)
      },
      /******************************************************************************
      * Function명 : fn_CheckCustNm
      * 설명       : 고객명 입력 후 focus out 될 때 호출
      ******************************************************************************/
      fn_CheckCustNm () { // 세대원 명 유효성 체크
        let lv_CustNmResult = this.hoshInfoListInqrVO.custNm.trim()
        let regexp_eng = '[a-zA-Z]' // 영문(대소)

        this.hoshInfoListInqrVO.custNm = lv_CustNmResult
        
        // 한글 10글자, 영문은 30글자 제한
        if (!this.$bizUtil.isEmpty(lv_CustNmResult)) {
          if (lv_CustNmResult.search(regexp_eng) > -1) { // 영문이 있으면
            lv_CustNmResult = lv_CustNmResult.substr(0, 30)
            this.hoshInfoListInqrVO.custNm = lv_CustNmResult
          } else {
            lv_CustNmResult = lv_CustNmResult.substr(0, 10)
            this.hoshInfoListInqrVO.custNm = lv_CustNmResult
          }
        }

        if (this.$bizUtil.isCustNameValidation(lv_CustNmResult, this.hoshInfoListInqrVO.knbScCd)) {
          this.custNmResult = true // 이름 유효성 체크 결과

          if (!this.isNew) { // 기존 세대원일 경우 이름변경여부 변수 true로 설정
            if (this.hoshInfoListInqrVO.custNmPrev !== this.hoshInfoListInqrVO.custNm) { // 최초 검색한 고객명과 다른 경우 고객명 변경 여부의 값을 true로 변경
              this.hoshInfoListInqrVO.isCustNmChanged = true
            } else { // 원래 이름으로 다시 수정해서 처리한 경우 고객명 변경 여부의 값을 false로 변경
              this.hoshInfoListInqrVO.isCustNmChanged = false
            }
          }
        } else {
          this.custNmResult = false // 이름 유효성 체크 결과
          this.custNmErrorMsg = this.$t('cm')['ECMC055'] // '유효하지 않은 고객명입니다. 정확한 정보를 확인하시기 바랍니다.(숫자,영문, 특수문자 등)'
        }

        this.$emit('custNmFocused', true)
        // event.target.blur() // 포커스out 처리 // *임시주석처리
      },
      /*********************************************************
       * Function명: fn_isExistCustClick
       * 설명: 고객카드조회 버튼 클릭 시 처리 함수
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_isExistCustClick (flag) {
        let lv_Vm = this

        this.existingCustomer = false
        this.searchRsltMsg = ''
        this.hoshInfoListInqrVO.searchFlag = ''
        this.hoshInfoListInqrVO.isDupCust = false
        this.isLoading = true

        if (this.hoshInfoListInqrVO.infoPrcusCnsntTypCd === '19') { // 마동만 선택 시, 성별코드+000000 처리된 주민번호로 셋팅
          this.hoshInfoListInqrVO.knbBkno = this.hoshInfoListInqrVO.knbBkFstNo + '000000'
          this.hoshInfoListInqrVO.knbBknoCipher = '' // 암호화필드 공백처리
        }

        let lv_UserInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보
        let lv_CustNm = lv_Vm.hoshInfoListInqrVO.custNm
        let pParams =
                      {
                        prcsfCd: flag, // 등록 플래그 값 01메인고객 02 세대원 (필수값)
                        custNm: lv_Vm.hoshInfoListInqrVO.custNm, // 고객명
                        knbFrno: lv_Vm.hoshInfoListInqrVO.knbFrno,
                        knbBknoCipher: lv_Vm.hoshInfoListInqrVO.knbBknoCipher,
                        knbBkno: lv_Vm.hoshInfoListInqrVO.knbBkno,
                        cnsltNo: lv_UserInfo.userId, // 컨설턴트 번호
                        celno: '', // 핸드폰번호 (생략)
                        infoPrcusCnsntTypCd: lv_Vm.hoshInfoListInqrVO.infoPrcusCnsntTypCd // 정보활용동의코드
                      }

        // GA 매니저 대행 컨설턴트 변경시
        if(lv_Vm.$cmUtil.fn_GachangeTF()){
          pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        }
      
        //매니저가 컨설턴트를 변경했을경우
        lv_Vm.stndKeyList = []
        if(lv_Vm.$cmUtil.fn_GachangeTF()){
          lv_Vm.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: lv_Vm.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
        }

        // let trnstId = 'txTSSCM13S1'
        let trnstId = 'txTSSCM13S2'

        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null) {
              if (!lv_Vm.$bizUtil.isEmpty(response.body.iCHoshInfoListInqrVO) &&
                  !lv_Vm.$bizUtil.isEmpty(response.body.iCHoshInfoListInqrVO[0]) &&
                  response.body.iCHoshInfoListInqrVO[0].chnlCustId !== undefined && response.body.iCHoshInfoListInqrVO[0].chnlCustId !== null &&
                  response.body.iCHoshInfoListInqrVO[0].chnlCustId.trim() !== '') { // 기존고객
                  lv_Vm.existingCustomer = false
                  lv_Vm.hoshInfoListInqrVO.isDupCust = true
                if(response.body.iCHoshInfoListInqrVO[0].chnlCustScCd !== '02'){ // 주민번호 확보가 되면 초기화 처리
                  lv_Vm.getStore('confirm').dispatch('ADD', '중복고객입니다. 고객정보를 다시 확인해주세요.')
                  lv_Vm.hoshInfoListInqrVO.searchFlag = ''
                  // 고객명, 주민번호 초기화
                  lv_Vm.hoshInfoListInqrVO.custNm = ''
                  lv_Vm.hoshInfoListInqrVO.knbFrno = ''
                  lv_Vm.hoshInfoListInqrVO.knbBkno = ''
                  lv_Vm.hoshInfoListInqrVO.knbBknoCipher = ''
                }else{
                  lv_Vm.getStore('confirm').dispatch('ADD', '기존고객으로 확인 되었습니다.')
                  lv_Vm.fn_SearchedDisabled()
                }
              } else { // 신규고객
                lv_Vm.existingCustomer = true
                lv_Vm.searchRsltMsg = '신규고객'
                lv_Vm.hoshInfoListInqrVO.searchFlag = lv_Vm.searchRsltMsg
                lv_Vm.hoshInfoListInqrVO.isDupCust = false
                // 세대원 고객카드조회 버튼 활성화 처리를 위한 VO 복사
                lv_Vm.hoshInfoListInqrVO.knbFrnoBkUp = JSON.parse(JSON.stringify(lv_Vm.hoshInfoListInqrVO.knbFrno))
                lv_Vm.hoshInfoListInqrVO.knbBknoCipherBkUp = lv_Vm.$bizUtil.isEmpty(lv_Vm.hoshInfoListInqrVO.knbBknoCipher) ? '':JSON.parse(JSON.stringify(lv_Vm.hoshInfoListInqrVO.knbBknoCipher))
                lv_Vm.hoshInfoListInqrVO.knbBknoBkUp = lv_Vm.$bizUtil.isEmpty(lv_Vm.hoshInfoListInqrVO.knbBkno) ? '':JSON.parse(JSON.stringify(lv_Vm.hoshInfoListInqrVO.knbBkno))
                lv_Vm.hoshInfoListInqrVO.celnoFrnoBkUp = lv_Vm.$bizUtil.isEmpty(lv_Vm.hoshInfoListInqrVO.celnoFrno) ? '':JSON.parse(JSON.stringify(lv_Vm.hoshInfoListInqrVO.celnoFrno))
                lv_Vm.hoshInfoListInqrVO.celnoBknoBkUp = lv_Vm.$bizUtil.isEmpty(lv_Vm.hoshInfoListInqrVO.celnoBkno) ? '':JSON.parse(JSON.stringify(lv_Vm.hoshInfoListInqrVO.celnoBkno))
                lv_Vm.hoshInfoListInqrVO.knbBkFstNoBkUp = JSON.parse(JSON.stringify(lv_Vm.hoshInfoListInqrVO.knbBkFstNo))
                lv_Vm.hoshInfoListInqrVO.custNmBkUp = JSON.parse(JSON.stringify(lv_Vm.hoshInfoListInqrVO.custNm))
                lv_Vm.$addSnackbar(lv_CustNm + " 님은 '" + lv_Vm.searchRsltMsg + "'으로 확인 되었습니다.")
                lv_Vm.fn_SearchedDisabled()
              }
              
              if (response.body.iCHoshInfoListInqrVO[0].foreignPartnerNo !== undefined && response.body.iCHoshInfoListInqrVO[0].foreignPartnerNo.trim() !== '') {
                lv_Vm.hoshInfoListInqrVO.foreignPartnerNo = response.body.iCHoshInfoListInqrVO[0].foreignPartnerNo.trim()
                lv_Vm.hoshInfoListInqrVO.bpCustEngNm = response.body.iCHoshInfoListInqrVO[0].bpCustEngNm.trim()
                lv_Vm.hoshInfoListInqrVO.bpPsprtNo = response.body.iCHoshInfoListInqrVO[0].bpPsprtNo.trim()
                lv_Vm.hoshInfoListInqrVO.bpNatyCd = response.body.iCHoshInfoListInqrVO[0].bpNatyCd.trim()
                lv_Vm.hoshInfoListInqrVO.bpNatyNm = response.body.iCHoshInfoListInqrVO[0].bpNatyNm.trim()
                lv_Vm.hoshInfoListInqrVO.bpDmclScCd = response.body.iCHoshInfoListInqrVO[0].bpDmclScCd.trim()
                lv_Vm.hoshInfoListInqrVO.bpDmclScCdNm = response.body.iCHoshInfoListInqrVO[0].bpDmclScCdNm.trim()
              }

              // 기존회원이 외국인이고, 국가정보 없으면 입력 권유
              if (lv_Vm.hoshInfoListInqrVO.knbScCd === 'ZPER02' && lv_Vm.$bizUtil.isEmpty(lv_Vm.hoshInfoListInqrVO.natyCd)) {
                // lv_Vm.fn_CntryInfoPop('Open')
                lv_Vm.$emit('fn_CntryInfoPop', 'Open', lv_Vm)
              }
            } else { // 조회 결과가 없는 경우
              if (response.msgComm.msgCd === 'EEAC928') {
                lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('co')['ECOC009']) // '조회하고자 하는 데이터가 없습니다'
              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
              }
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })

      },
      /******************************************************************************
      * Function명 : fn_ValidateBeforeSelCust
      * 설명       : 고객카드조회 버튼 클릭 시 호출 - 화면내 validation을 먼저 수행함.
      *              validation결과는 fn_Validation함수에서 확인 가능
      ******************************************************************************/
      fn_ValidateBeforeSelCust () {
        let lv_RetVal = true
        let lv_CheckAge = this.$bizUtil.compareCurrentAge(this.hoshInfoListInqrVO.knbFrno, 14)

        if(this.hoshInfoListInqrVO.custNm.trim().length === 0 ){
          this.getStore('confirm').dispatch('ADD', '이름은 필수입력 항목입니다.')
          return lv_RetVal = false
        }

        // 신규, 임시고객일 경우 필수컨설팅 동의시에만 주민번호 체크
        if (this.hoshInfoListInqrVO.isNew || this.hoshInfoListInqrVO.chnlCustScCd === '02') {
          if (this.hoshInfoListInqrVO.infoPrcusCnsntTypCd !== '19') {
            if (this.hoshInfoListInqrVO.knbFrno.trim().length < 6) {
              this.getStore('confirm').dispatch('ADD', '필수컨설팅 동의고객은 주민번호 앞자리 필수 입력입니다.')
              return lv_RetVal = false
            } else if (this.$bizUtil.isEmpty(this.fn_KnbBkno)) {
              this.getStore('confirm').dispatch('ADD', '필수컨설팅 동의고객은 주민번호 뒷자리 필수 입력입니다.')
              return lv_RetVal = false
            } else if (this.chkKnbResult === false) { // 주민번호 뒷자리 입력 후 , 주민번호 유효성 체크 결과
              this.getStore('confirm').dispatch('ADD', '유효하지 않은 주민등록번호입니다.')
              return lv_RetVal = false
              
            }
          }
        }

        if (lv_CheckAge === true) {
          this.getStore('confirm').dispatch('ADD', '14세 이상 세대원은 별도로 모바일 동의를 진행 하시기 바랍니다.')
          return lv_RetVal = false
        } else {
          if ( Number(this.today) < Number(this.$bizUtil.addBirthFrontYear(this.hoshInfoListInqrVO.knbFrno)) ) {
            this.getStore('confirm').dispatch('ADD', '미래일자는 입력 불가능합니다.')
            return lv_RetVal = false
          }
        }
        
        return lv_RetVal
      },
      /******************************************************************************
      * Function명 : fn_BtnS3OnClick
      * 설명       : 보험직업 검색 팝업 창 호출
      ******************************************************************************/
      fn_BtnS3OnClick () {
        // this.$popupManager.open(TSSSP112P,
        //   {
        //     callbackFn: this.fn_ConfirmTSSSP112P
        //   }
        // )
        let lv_Vm = this
        lv_Vm.popup013 = lv_Vm.$bottomModal.open(MSPCM013P, {
            properties: {
              pJobCdNm : lv_Vm.searchJobcdNm
            },
            listeners: {
              confirmPopup: (pData) => {
                lv_Vm.$bottomModal.close(lv_Vm.popup013)

                // 파라미터 세팅
                lv_Vm.fn_ConfirmMSPCM013P(pData)
              },
              cancelPopup: (pData) => {
                lv_Vm.$bottomModal.close(lv_Vm.popup013)
              }
            }
          })
      },
      /******************************************************************************
      * Function명 : fn_ConfirmMSPCM013P
      * 설명       : MSPCM013P '확인'을 선택한 후 호출되는 함수
      ******************************************************************************/
      fn_ConfirmMSPCM013P (param) {
        if (param !== null && typeof param !== 'undefined'){
          if (this.isNew === false && this.hoshInfoListInqrVO.custJobCd !== '' && this.hoshInfoListInqrVO.custJobCd !== param.jobCd) { // 기존에 선택된 직업코드와 다를 경우 변경된것으로 처리
            this.hoshInfoListInqrVO.isCustJobCdChanged = true
          }

          this.hoshInfoListInqrVO.custJobCd = param.jobCd
          this.hoshInfoListInqrVO.custJobCdNm = param.jobCdNm
          // 직업 버튼 처리
          if (this.hoshInfoListInqrVO.custJobCd !== undefined && this.hoshInfoListInqrVO.custJobCd.trim() !== '') {
            let lv_Vm = this

            let tmpJobMain = lv_Vm.childJobs.filter(
              (val) => {
                if (val.value === lv_Vm.hoshInfoListInqrVO.custJobCd) {
                  return val
                }
              }
            )

            if (tmpJobMain.length > 0) { // 매핑되는 값이 있는 경우
              this.childJob = tmpJobMain[0].value
            }else{
              if(!this.$bizUtil.isEmpty(this.hoshInfoListInqrVO.custJobCdNm)){
                this.childJob = ''
              }
            }
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_Validation
      * 설명       : 세대원 카드 유효성 체크 - 결과가 false인 경우 해당 component로 focus 이벤트 처리
      ******************************************************************************/
      fn_Validation () {
        let lv_Vm = this
        let lv_Scope = '__global__'
        lv_Vm.$validator.validateAll(lv_Scope).then((result) => {
          let lv_Result = result
          if (lv_Result === false) {
            lv_Vm.isOpen = true // 접혀져 있을 경우가 있기에 isOpen을 true로 해서 세대원카드가 펼쳐지게 함.
            let lv_Var = lv_Vm.$validator.errors.items[0].field.split('_')[0] // validator의 name은 변수명_채널고객ID로 되어 있음. 해당 component의 필드명을 받기 위해 _이하는 잘라냄.
            if (lv_Var === 'hoshInfoListInqrVO.chkAgreeType') { // 동의 유형인 경우 label 태그로 포커스를 주어야 하기에 focus함수를 써야 함.
              lv_Vm.$nextTick( () => { // DOM의 상태가 변경 될 때 호출(isOpen이 true로 되어서 접혀져 있는 화면이 열린 후에 포커스 이동 처리)
                lv_Vm.$refs[lv_Var].focus()
                lv_Vm.$emit('moveScroll', lv_Vm.$refs['hoshInfoListInqrVO.chkAgreeType'].offsetTop - 20) // 해당 위치로 스크롤 이동 요청
              })
            } else if (lv_Var === 'hoshInfoListInqrVO.knbFrno') { //
              lv_Vm.$nextTick( () => { // DOM의 상태가 변경 될 때 호출(isOpen이 true로 되어서 접혀져 있는 화면이 열린 후에 포커스 이동 처리)
                lv_Vm.$refs['lbKnbFrno'].focus() // textinput에 포커스를 줄 경우 숫자 키패드가 뜸. 이를 방지하기 위해 label에 포커스를 주고 스크롤 이동 처리함.
                lv_Vm.$emit('moveScroll', lv_Vm.$refs['lbKnbFrno'].offsetTop) // 해당 위치로 스크롤 이동 요청
              })
            } else if (lv_Var === 'hoshInfoListInqrVO.celno') { //
              lv_Vm.$nextTick( () => { // DOM의 상태가 변경 될 때 호출(isOpen이 true로 되어서 접혀져 있는 화면이 열린 후에 포커스 이동 처리)
                lv_Vm.$refs['lbCelno'].focus() // textinput에 포커스를 줄 경우 숫자 키패드가 뜸. 이를 방지하기 위해 label에 포커스를 주고 스크롤 이동 처리함.
                lv_Vm.$emit('moveScroll', lv_Vm.$refs['lbCelno'].offsetTop) // 해당 위치로 스크롤 이동 요청
              })
            } else {
              lv_Vm.$nextTick( () => { // DOM의 상태가 변경 될 때 호출(isOpen이 true로 되어서 접혀져 있는 화면이 열린 후에 포커스 이동 처리)
                lv_Vm.$refs[lv_Var].setFocus()
              })
            }
          }

          lv_Vm.$emit('validateCompleted', lv_Result)
        })
      },
      fn_getMaskType (param) { // ios에서 넘어온 mtranskey key
        this.encKey = param
        this.hoshInfoListInqrVO.knbBkno = this.encKey
      },
      /******************************************************************************
      * Function명 : fn_ChildFocusOut
      * 설명       : 입력 세대원 유효성 체크
      ******************************************************************************/
      fn_ChildFocusOut (btnType) {
        const lv_Vm = this
        let Msg = ''

        // 고객명 체크
        if (this.$bizUtil.isEmpty(this.hoshInfoListInqrVO.custNm)) {
          Msg = '이름을 입력해주세요.'
          this.$refs['custNmRef'].validate()
          this.getStore('confirm').dispatch('ADD', Msg)
          return
        } 
        
        let regexp_eng = '[a-zA-Z]' // 영문(대소)
        this.hoshInfoListInqrVO.custNm = this.hoshInfoListInqrVO.custNm.toUpperCase()
        
        // 외국인 여부 체크
        if (this.hoshInfoListInqrVO.custNm.search(regexp_eng) > -1 && !this.fn_IsForeigner) { // 영문이 있으면
          Msg = '외국인 체크를 해주세요'
          this.getStore('confirm').dispatch('ADD', Msg)
          return
        }

        // 주민번호 체크
        this.$nextTick(_ => {
          this.$refs['knbFrno'].validate()
        })
        if (!_.isEmpty(this.$refs['knbBkno'])) {
          this.$refs['knbBkno'].validate()
        }
        // 주민번호 - 앞자리
        if (this.hoshInfoListInqrVO.knbFrno.length !== 6) {
          Msg = '생년월일을 입력해주세요.'
          this.getStore('confirm').dispatch('ADD', Msg)
          return
        }

        // 주민번호 - 뒷자리)
        if (!this.$commonUtil.isMobile()) { // PC일 경우
          if (this.hoshInfoListInqrVO.infoPrcusCnsntTypCd === '19') { // 마동
            if (this.$bizUtil.isEmpty(this.hoshInfoListInqrVO.knbBkFstNo)) {
              Msg = '주민등록번호 뒷자리를 확인하세요.'
              this.getStore('confirm').dispatch('ADD', Msg)
  
              this.hoshInfoListInqrVO.fn_KnbBkno = ''
              this.hoshInfoListInqrVO.knbBknoCipher = ''
              return
            }
          } else {
            if (this.$bizUtil.isEmpty(this.hoshInfoListInqrVO.knbBkno) || this.hoshInfoListInqrVO.knbBkno.substring(1) === '000000') {
              Msg = this.hoshInfoListInqrVO.custNm + '님의 '
              Msg += this.fn_IsForeigner ? '외국인등록번호' : '주민등록번호'
              Msg += ' 뒷자리를 입력해주세요.'

              let alertProps = {
                msg: Msg,
                closeBtn: false,
                preventTouchClose: false,
              }

              if (btnType === 'autoSearch') {
                let callbackFunc = this.$refs['mkKnbBkno'].onClick
                this.$cmUtil.fn_OpenAlert(lv_Vm, alertProps, callbackFunc)
              } else {
                this.$cmUtil.fn_OpenAlert(lv_Vm, alertProps)
              }

              this.hoshInfoListInqrVO.fn_KnbBkno = ''
              this.hoshInfoListInqrVO.knbBknoCipher = ''
              return
            }
          }
        } else { // 모바일일 경우
          if (this.hoshInfoListInqrVO.infoPrcusCnsntTypCd === '19') { // 마동
            if (this.$bizUtil.isEmpty(this.hoshInfoListInqrVO.knbBkFstNo)) {
              Msg = '주민등록번호 뒷자리를 확인하세요.'
              this.getStore('confirm').dispatch('ADD', Msg)
  
              this.hoshInfoListInqrVO.fn_KnbBkno = ''
              this.hoshInfoListInqrVO.knbBknoCipher = ''
              return
            }
          } else { // 필컨/모두 동의
            if (this.$bizUtil.isEmpty(this.hoshInfoListInqrVO.knbBknoCipher) || this.hoshInfoListInqrVO.knbBknoCipher.substring(1) === '000000') {
              Msg = this.hoshInfoListInqrVO.custNm + '님의 '
              Msg += this.fn_IsForeigner ? '외국인등록번호' : '주민등록번호'
              Msg += ' 뒷자리를 입력해주세요.'

              let alertProps = {
                msg: Msg,
                closeBtn: false,
                preventTouchClose: false,
              }

              if (btnType === 'autoSearch') {
                let callbackFunc = this.$refs['mkKnbBkno'].onClick
                this.$cmUtil.fn_OpenAlert(lv_Vm, alertProps, callbackFunc)
              } else {
                this.$cmUtil.fn_OpenAlert(lv_Vm, alertProps)
              }

              this.hoshInfoListInqrVO.fn_KnbBkno = ''
              this.hoshInfoListInqrVO.knbBknoCipher = ''
              return
            }
          }
        }

        if (this.hoshInfoListInqrVO.infoPrcusCnsntTypCd !== '19') { // 마동만 아닐때
          this.fn_CheckSsnValidation() // 주민번호 유효성 체크
        } else {
          this.fn_CheckAgePossible() // 14세 미만 체크
        }
      },
      /******************************************************************************
      * Function명 : fn_CheckSsnValidation
      * 설명       : 주민번호 유효성 체크
      ******************************************************************************/
      fn_CheckSsnValidation () {
        let lv_Vm = this
        this.isLoading = true
        let lv_Knb = (lv_Vm.$commonUtil.isMobile()) ? '' : lv_Vm.hoshInfoListInqrVO.knb // PC일 경우 전체 평문 주민번호를 넘겨줌. 모바일일 경우 공백처리
        // let lv_Knb = lv_Vm.hoshInfoListInqrVO.knb // GA테스트용 삭제처리
        let encKey = this.$commonUtil.getIsIOS() ? 'IOS' : ''

        let pParams =
                      {
                        knb: lv_Knb, // 전체 주민번호
                        knbFrno: lv_Vm.hoshInfoListInqrVO.knbFrno, // 주민번호 앞번호
                        knbBknoCipher: lv_Vm.hoshInfoListInqrVO.knbBknoCipher, // 주민번호 뒷번호
                        // knbBknoCipher: '', // GA테스트용 삭제처리
                        KnbScCd: encKey
                      }

        let trnstId = 'txTSSCM10S6'
        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null) {
              if (response.body.ssnYn === 'Y') {
                // 주민번호 유형값 세팅
                let lv_KnbBkno = response.body.knb.substring(6, 7)
                lv_Vm.hoshInfoListInqrVO.knbBkFstNo = lv_KnbBkno
                lv_Vm.hoshInfoListInqrVO.knbBackup = response.body.knb

                // 성별구분값
                if (lv_KnbBkno === '1' || lv_KnbBkno === '3' || lv_KnbBkno === '5' || lv_KnbBkno === '7' || lv_KnbBkno === '9') {
                  lv_Vm.hoshInfoListInqrVO.sxdsCd = '2' // 1 - 여성, 2 - 남성, "" - 알수없음
                } else if (lv_KnbBkno === '2' || lv_KnbBkno === '4' || lv_KnbBkno === '6' || lv_KnbBkno === '8') {
                  lv_Vm.hoshInfoListInqrVO.sxdsCd = '1' // 1 - 여성, 2 - 남성, "" - 알수없음
                }

                if (lv_KnbBkno === '1' || lv_KnbBkno === '2' || lv_KnbBkno === '3' || lv_KnbBkno === '4') {
                  lv_Vm.hoshInfoListInqrVO.knbScCd = 'ZPER01'
                  lv_Vm.hoshInfoListInqrVO.isForeigner = false
                } else if (lv_KnbBkno === '5' || lv_KnbBkno === '6' || lv_KnbBkno === '7' || lv_KnbBkno === '8' || lv_KnbBkno === '9') {
                  lv_Vm.hoshInfoListInqrVO.knbScCd = 'ZPER02'
                  lv_Vm.hoshInfoListInqrVO.isForeigner = true
                } else {
                  lv_Vm.hoshInfoListInqrVO.knbScCd = ''
                  lv_Vm.hoshInfoListInqrVO.isForeigner = false
                }
                
                // 14세 미만 고객인지 체크
                lv_Vm.fn_CheckAgePossible()  // lv_Vm.fn_ChildCheckAgreePossible()

                lv_Vm.chkKnbResult = true
                lv_Vm.hoshInfoListInqrVO.chkKnbResult = true
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

              lv_Vm.chkKnbResult = false // 주민번호가 유효하지 않음 - validation체크시 사용
              lv_Vm.hoshInfoListInqrVO.chkKnbResult = false // 주민번호가 유효하지 않음 - validation체크시 사용
              
              if (lv_Vm.$commonUtil.isMobile()) {
                lv_Vm.hoshInfoListInqrVO.knbBknoCipher = ''
              } else {
                lv_Vm.hoshInfoListInqrVO.knbBkno = ''
              }
            }

            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })

      },
      /******************************************************************************
      * Function명 : fn_CheckAgePossible
      * 설명       : 만 14세 이상인지 여부 확인 후, 동의 여부 선택 가능한지 체크 - 필컨,마동 체크 선택이나 주민번호 입력 시 체크함.
      ******************************************************************************/
      fn_CheckAgePossible () {
        if (this.hoshInfoListInqrVO.selcAgreeTypeMcnst || this.hoshInfoListInqrVO.selcAgreeTypeMkt) { // 필컨, 마동 둘중 하나라도 선택되어 있으면 14세 이상인지 체크
          if (this.hoshInfoListInqrVO.knbFrno !== '' && this.hoshInfoListInqrVO.knbFrno.length === 6) {
            let lv_CheckAge = this.$bizUtil.compareCurrentAge(this.hoshInfoListInqrVO.knbFrno, 14)

            if (lv_CheckAge === true) {
              this.getStore('confirm').dispatch('ADD', '14세미만 미성년자만 추가할수 있습니다.')
              this.hoshInfoListInqrVO.knbFrno = ''
              this.hoshInfoListInqrVO.knbBkFstNo = ''
              this.hoshInfoListInqrVO.fn_KnbBkno = ''
              this.hoshInfoListInqrVO.knbBkno = ''
              this.hoshInfoListInqrVO.knbBknoCipher = ''
              this.chkKnbResult = false
              this.hoshInfoListInqrVO.chkKnbResult = false
            } else {
              if ( Number(this.today) < Number(this.$bizUtil.addBirthFrontYear(this.hoshInfoListInqrVO.knbFrno)) ) {
                this.getStore('confirm').dispatch('ADD', '미래일자는 입력 불가능합니다.')
                this.hoshInfoListInqrVO.knbFrno = ''
                this.hoshInfoListInqrVO.knbBkFstNo = ''
                this.hoshInfoListInqrVO.knbBkno = ''
                this.hoshInfoListInqrVO.knbBknoCipher = ''
                this.chkKnbResult = false
                this.hoshInfoListInqrVO.chkKnbResult = false
                return
              }
              this.chkKnbResult = true
              this.hoshInfoListInqrVO.chkKnbResult = true
              if (this.hoshInfoListInqrVO.customerType === 'new' || this.hoshInfoListInqrVO.chnlCustScCd === '02') {
                this.fn_isExistCustClick('02')
              }
            }
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_SearchedDisabled
      * 설명       : 고객조회 후 Disabled 처리
      ******************************************************************************/
      fn_SearchedDisabled () {
        this.disabledObj.custNmdDisabled = this.disabledObj.mcnstDisabled = this.disabledObj.mktDisabled = this.disabledObj.knbFrnoDisabled =
        this.disabledObj.knbBknoDisabled = this.disabledObj.celnoFrnoDisabled = this.disabledObj.celnoBknoDisabled = this.disabledObj.jobMainDisabled =
        this.disabledObj.jobNmDisabled = this.disabledObj.chkForeignerDisabled = true

        // 조회후 세대원 카드 접힘
        // this.$refs['listItem'].isExpand = false
      },
      /******************************************************************************
      * Function명 : fn_ToggleFloating
      * 설명       : 토글 처리
      ******************************************************************************/
      fn_ToggleFloating(v) {
        this.$emit('toggle-floating', v)
      },
      /*********************************************************
     * Function명: fn_BtnCntryCodeClick
     * 설명: 국가코드/여권 버튼 클릭 시 호출
     * Params: positive
     * Return: N/A
     *********************************************************/
      fn_BtnCntryCodeClick () {
        // this.fn_callAlienRegistrationPlugin()
        this.fn_OpenMSPCM019P()
      },
      /******************************************************************************
      * Function명 : fn_callAlienRegistrationPlugin
      * 설명       : 외국인등록증 스크래핑 플러그인 호출
      *******************************************************************************/
      fn_callAlienRegistrationPlugin () {
        /*
        let lv_Vm = this
        let lv_rrn = ''
        let lv_type = ''

        if (lv_Vm.$commonUtil.isMobile()) { // 모바일인 경우 신분증 진위확인 native호출
          if (lv_Vm.searchRsltMsg === '기존고객') {
            lv_rrn = lv_Vm.hoshInfoListInqrVO.prextKnb
            lv_type = 'A'
          } else {
            lv_rrn = lv_Vm.hoshInfoListInqrVO.knbFrno + lv_Vm.hoshInfoListInqrVO.knbBknoCipher
            lv_type = 'B'
          }
          // 외국인등록증 진위확인
          window.fdpbridge.exec('alienRegistrationPlugin', {rrn: lv_rrn, type: lv_type}, function (result) {
            let obj = result.data
            // 성공콜백
            if (obj === 'Y') { // 신분증인증성공
              // 외국인고객 스크래핑 여부
              lv_Vm.hoshInfoListInqrVO.scrapYn = 'Y'
              
              //MSPCM015P 호출
              lv_Vm.fn_OpenMSPCM015P()

            } else if (obj === 'N') { // 실패(취소)
              lv_Vm.getStore('toast').dispatch('ADD', '신분증 인증을 취소하셨습니다.')
            } else {
              // 기타 예외
              lv_Vm.getStore('toast').dispatch('ADD', '신분증 인증에 실패하였습니다. 잠시후에 시도해주세요.')
            }
          }, function (result) {
            // 실패콜백
            lv_Vm.getStore('confirm').dispatch('ADD', result.data)
          })
        } else { // PC에서 하는 경우
          // 외국인고객 스크래핑 여부
          lv_Vm.hoshInfoListInqrVO.scrapYn = 'Y'

          //MSPCM015P 호출
          lv_Vm.fn_OpenMSPCM015P()
        }
        */
      },
      fn_OpenMSPCM019P () {
        let lv_Vm = this

        lv_Vm.popup019 = lv_Vm.$bottomModal.open(MSPCM019P, {
          properties: {
            tempCustCardVo: lv_Vm.hoshInfoListInqrVO
          },
          listeners: {
            onPopupCancel: () => {
              lv_Vm.$bottomModal.close(lv_Vm.popup019)
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_Vm.popup019)
              // 외국인고객 스크래핑 여부
              lv_Vm.hoshInfoListInqrVO.scrapYn = 'Y'

              //MSPCM015P 호출
              setTimeout(function() {
                lv_Vm.fn_OpenMSPCM015P()
              }, '500')
            }
          }
        })
      },
      /*********************************************************
       * Function명: fn_CountryCodePopRtn
       * 설명: 국가코드/여권 팝업 확인버튼 클릭 시 호출
       * Params: positive
       * Return: N/A
       *********************************************************/
      fn_CountryCodePopRtn (value) {
        if (value !== undefined && value !== null) {
          Object.assign(this.hoshInfoListInqrVO, value)

          value.psprtNo = value.psprtNo === 'IMSI' ? 'IMSI' + value.knbFrno : value.psprtNo

          this.hoshInfoListInqrVO.bpPsprtNo = value.psprtNo,
          this.hoshInfoListInqrVO.bpNatyNm = value.natyNm
        }
      },
      /*********************************************************
       * Function명: fn_OpenMSPCM015P
       * 설명: 외국인 등록 정보 등록 팝업 열기
       *********************************************************/
      fn_OpenMSPCM015P (){
        let lv_Vm = this

        if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.hoshInfoListInqrVO.foreignPartnerNo) && lv_Vm.hoshInfoListInqrVO.foreignPartnerNo === 'E999999999') {
          lv_Vm.getStore('confirm').dispatch('ADD', '해당고객은 거래종료, 비정상, 다건 조회 등의 사유로 조회가 불가 합니다.지점 담당자를 통해 처리 후 다시 시도하시기 바랍니다.')
          return
        }

        lv_Vm.popup015 = lv_Vm.$bottomModal.open(MSPCM015P, {
          properties: {
            title: '외국인 등록 정보', // 팝업의 타이틀 셋팅
            tempCustCardVo : lv_Vm.hoshInfoListInqrVO
          },
          listeners: {
            confirmPopup: (pData) => {
              lv_Vm.$bottomModal.close(lv_Vm.popup015)

              // 파라미터 세팅
              lv_Vm.fn_CountryCodePopRtn(pData)
            }
          }
        })
      },
      /*********************************************************
       * Function명: fn_ItemFocus
       * 설명: 고객명 포커스 이벤트
       *********************************************************/
      fn_ItemFocus() {
        this.$refs['listItem'].isExpand = true
        this.$emit('custNmFocused', false, this.custNmLb)
      },
      /*********************************************************
       * Function명: fn_KnbFrnoFocusType
       * 설명: 본인보험금 주민번호 오토포커스
       *********************************************************/
      fn_KnbFrnoFocusType(event, opt) {
          console.log('fn_KnbFrnoFocusType.....')                        
          if(this.$commonUtil.isMobile()) {
            if( this.fn_KnbFrno.length == 6 ) {
              if( event.key == 'v' || this.$commonUtil.isMobile() ) {
                this.$refs.mkKnbBkno.onClick()
              }
            }
          } 
      },
    }//methods end
  }
</script>
<style scoped>
</style>