/*
* 업무구분: 고객등록
* 화 면 명: MSPCM016P
* 화면설명: 세대원 입력
* 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
* 작 성 일: 2022.06.30
* 작 성 자: 이태훈
*/
<template>
  <ur-page-container title="세대추가" :show-title="true" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
        
        <!-- 기존 세대원 추가 start -->
        <MSPCM016D ref="mspcm016dExist"
            v-for="hoshInfoListInqrVO in existHoshInfoListInqrVO" :key="hoshInfoListInqrVO.chnlCustId" v-bind:hoshInfoListInqrVO="hoshInfoListInqrVO"
            @validateCompleted="fn_ChildValidateCompleted" @delHosh="fn_DelExistHosh" @toggle-floating="fn_ToggleFloating"
            @fn_CntryInfoPop="fn_CntryInfoPop"/>

        <!-- 신규 세대원 추가 start -->
        <MSPCM016D ref="mspcm016dNew"
            v-for="hoshInfoListInqrVO in newHoshInfoListInqrVO" :key="hoshInfoListInqrVO.chnlCustId" v-bind:hoshInfoListInqrVO="hoshInfoListInqrVO"
            @validateCompleted="fn_ChildValidateCompleted" @delHosh="fn_DelNewHosh"  @moveScroll="fn_MoveScroll" @toggle-floating="fn_ToggleFloating"
            @fn_CntryInfoPop="fn_CntryInfoPop"/>

        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-round-btn">
          <!-- <mo-button v-is-visible="{acl: ['I']}" class="ns-btn-round" @click="fn_ValidateBeforeAddHosh">세대원 추가</mo-button> -->
          <mo-button class="ns-btn-round" @click="fn_ValidationChild">세대원 추가</mo-button>
        </ur-box-container>
      </ur-box-container>

      <div class="ns-height40"></div>
      <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative" :style="{'z-index':bottomSelectShow?-1:9}">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClosePopup">취소</mo-button>
          <!-- <mo-button v-is-visible="{acl: ['U']}" componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ValidateBeforeNext">확인</mo-button> -->
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Confirm">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
    <mo-bottom-sheet ref="bottomSheetCntryInfo" class="ns-bottom-sheet ns-style3">
      <div>
      <p class="cancel-ment">
        외국인 고객이시네요.<br>외국인 정보를 추가 입력하시겠습니까?
      </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_007" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CntryInfoPop('Close')">다음에 하기</mo-button>
            <mo-button componentid="mo_button_008" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CntryInfoPop('Add')">추가</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPCM016D from '@/ui/cm/MSPCM016D' //세대원 상세영역
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM016P",
    screenId: "MSPCM016P",
    components: {
      MspBottomSelect,
      MSPBottomAlert,
      MSPCM016D,
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    props: {
      custCardVO: Object ,
      infoPrcusCnsntTypCd: String,
      selcAgreeTypeMcnst: Boolean,
      selcAgreeTypeMkt: Boolean,

      pExistHoshInfoListInqrVO: {
        type: Array,
        default: _ => []
      },
      pNewHoshInfoListInqrVO: {
        type: Array,
        default: _ => []
      },
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    beforeDestroy () {
    //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked) // stop listenning Event
    window.removeEventListener('resize', this.onResize)
    /*
    if (!this.$commonUtil.isMobile()) { // PC에서만 작동
      let lv_Vm = this
      window.removeEventListener('keyup', function (e) {
        if (e.keyCode === 33 && e.currentTarget.document.URL.indexOf('TSSSP101M') > 0) { // PC의 PageUp 버튼 클릭 시
          lv_Vm.fn_BackBtnClicked()
        }
      })
    }
    */
  },
    created() {
      this.existHoshInfoListInqrVO = this.$bizUtil.cloneDeep(this.pExistHoshInfoListInqrVO)
      this.newHoshInfoListInqrVO = this.$bizUtil.cloneDeep(this.pNewHoshInfoListInqrVO)

      this.newChannelId = this.newHoshInfoListInqrVO.legnth  === 0 ?  0 : -1

      this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
        // 기존 고객과 신규고객 정보가 없을 경우만 자동 펼침 처리
        if( ( this.$bizUtil.isEmpty(this.existHoshInfoListInqrVO) || this.existHoshInfoListInqrVO.length === 0) && 
            ( this.$bizUtil.isEmpty(this.newHoshInfoListInqrVO) || this.newHoshInfoListInqrVO.length === 0)  ){
          this.fn_AddHosh()
        }
      })

    },
    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      this.$bizUtil.insSrnLog('MSPCM016P')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isOpen: true,
        custNmResult: true, // 이름 유효성 체크 결과
        custNmErrorMsg: '', // 이름 유효성 결과 에러 메시지
        msgDelHoshConfirm: this.$t('cm')['ECMC014'], // '삭제하시겠습니까?'
        // 기존 세대원 리스트
        existHoshInfoListInqrVO: [],
        // 신규 세대원 리스트
        newHoshInfoListInqrVO: [],
        totalChildValidationCount: 0, // 세대원 카드 유효성 전체 체크갯수
        currentChildValidationCount: 0, // 세대원 카드 유효성 현재 체크갯수
        isChildValidationResult: true, // 세대원 카드 유효성 결과
        hoshInfoVO: {
          customerType: 'new', // 주고객이 기존, 신규인지 여부
          selcAgreeTypeMkt: '', // 주고객 마케팅 동의여부
          selcAgreeTypeMcnst: '', // 주고객 필컨 동의여부
          cnsltNo: '', // 컨설턴트번호
          chnlCustId: '', // 채널 고객ID
          trtrEno: '', // 처리자사번
          chnlCstgrId: '', // 채널고객그룹ID
          chnlCustScCd: '', // 가망 임시 구분
          agreeTypeMcnst: false, // 필수컨설팅 체크
          agreeTypeMkt: false, // 마케팅동의 체크
          isCelnoChanged: false,
          celno: '', // 휴대전화
          prcsfCd: 'I', // I:신규, U:업데이트
          celnoFrno: {}, // 휴대전화 앞자리
          celnoBkno: '', // 휴대전화 뒷자리
          isCustJobCdChanged: false,
          custJobCd: '', // 직업코드
          custJobCdNm: '', // 직업명
          custNm: '', // 이름
          custNmPrev: '', // 원본 이름(이름명 변경시 체크)
          scrapYn: '', // 스크래핑진위확인 여부
          custEngNm: '', // 고객 영문명
          natyCd: '', // 국가코드
          natyNm: '', // 국가코드명
          psprtNo: '', // 여권번호
          dmclScCd: '', // 거주지구분코드
          dmclScCdNm: '', // 거주지구분코드명
          foreignPartnerNo: '', // 외국인고객번호
          bpCustEngNm: '', // BP 영문명
          bpPsprtNo: '', // BP 여권번호
          bpNatyCd: '', // BP 국적코드
          bpNatyNm: '', // BP 국적명
          bpDmclScCd: '', // BP 거주지구분코드
          bpDmclScCdNm: '', // BP 거주지구분코드명
          isCustNmChanged: false, // 고객명 변경 여부
          knb: '', // 전체주민번호
          knbBackup: '', // 전체주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
          knbFrno: '', // 주민번호 앞자리
          knbBkno: '', // 주민번호 뒷자리
          knbBknoCipher: '', // 주민번호 뒷자리 암호화
          knbBknoCipherBackup: '', // 주민번호 뒷자리 백업(암호화 값이 들어올 경우에만 임시 보관장소로 쓰임)
          knbBkFstNo: '', // 주민번호 뒷자리 성별구분코드
          isKnbChanged: false, // 주민번호 변경 여부
          sxdsCd: '', // 성별
          dob: '', // 생년월일
          knbScCd: 'ZPER01', // 국적구분코드 (ZPER01: 내국인, ZPER02: 외국인)
          unAprvCustYn: 'Y', // 미승인 여부 Y:미승인, N: 승인,
          mnCustRltnCd: '03', // 주고객관계코드
          ralBrthd: '', // 실제 생년월일
          samScCd: '', // 음력,양력 구분
          rlnmCnfRsltCd: '', // 실명확인결과코드
          hoshMnYn: '', // 세대주구분
          mcnstYmd: '', // 필수 컨설팅 만료일
          mktYmd: '', // 마케팅 만료일,
          isPossibleFetus: true, // 태아 추가 가능 여부
          isNew: true, // 신규 세대원인지 여부
          searchFlag: '', // 고객카드조회 여부
          isDupCust: false, // 고객카드중복여부
          isMobile: 'Y', // 모바일 사용여부
          iCCustInfoPrcusCnsntVO: {}, // 정보활용동의 내역 객체
        },
        // 신규 세대원 추가시 임시 고객 채널 ID생성
        newChannelId: 0,
        bottomSelectShow:false,
        currentTar: {}
      } //return end
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {  
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_DelNewHosh
      * 설명       : 세대원 삭제 버튼 클릭 시 호출 (신규 일때만 삭제 가능)
      ******************************************************************************/
      fn_DelNewHosh (data) {
        let delIdx = -1 // 초기값 설정
        for (let i = 0, len = this.newHoshInfoListInqrVO.length; i < len; i++) { // 삭제하고자 하는 세대원 카드 index 찾기
          if (this.newHoshInfoListInqrVO[i].chnlCustId === data) {
            delIdx = i
            break
          }
        }

        if (delIdx !== -1) {
          this.$delete(this.newHoshInfoListInqrVO, delIdx)
          delIdx = -1
        }
      },
      /*********************************************************
       * 신규 세대원의 동의유형선택 미 선택 시 해당 위치로 스크롤 이동처리
       *
       *********************************************************/
      fn_MoveScroll (value) {
        this.$refs.scroll.$el.scrollTop = value
      },
      onResize () { // input box의 포커스 될 경우, 가상 키패드가 올라오는 시점의 resize이벤트 발생. 이때, scrollTop값을 변경함으로 해당 inputbox가 화면 상단으로 오도록 처리
        if (this.focusRefName !== '' && this.focusRefName !== undefined && this.focusRefName !== null) {
          if (this.$refs[this.focusRefName] !== '' && this.$refs[this.focusRefName] !== undefined && this.$refs[this.focusRefName] !== null) { // 주 고객 input box의 scrollTop 처리
            this.$refs.scroll.$el.scrollTop = this.$refs[this.focusRefName].offsetTop - 20
          }
          // 세대원에 있는 ref인지 먼저 체크
          for (let i = 0; i < this.existHoshInfoListInqrVO.length; i++) { // 기존 세대원의 inputbox의 scrollTop 처리
            let lv_ObjExist = this.$refs.mspcm016dExist[i].$refs[this.focusRefName]
            if (lv_ObjExist !== '' && lv_ObjExist !== undefined && lv_ObjExist !== null) {
              this.$refs.scroll.$el.scrollTop = lv_ObjExist.offsetTop - 20
            }
          }

          for (let i = 0; i < this.newHoshInfoListInqrVO.length; i++) { // 신규 세대원의 inputbox의 scrollTop처리
            let lv_ObjNew = this.$refs.mspcm016dNew[i].$refs[this.focusRefName]
            if (lv_ObjNew !== '' && lv_ObjNew !== undefined && lv_ObjNew !== null) {
              this.$refs.scroll.$el.scrollTop = lv_ObjNew.offsetTop - 20
            }
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_ChildValidateCompleted
      * 설명       : 세대원 카드 유효성 체크 결과 함수 - 각 세대원 카드별로 validation 체크후, 이 함수 호출
      ******************************************************************************/
      fn_ChildValidateCompleted (result) {
        this.currentChildValidationCount++ // 세대원 카드에서 유효성 체크 카운트 진행
        if (result === false) {
          this.isChildValidationResult = false
        }

        // 세대원 카드의 유효성 결과가 true이고, 현재까지 진행된 세대원 카드의 유효성 체크 갯수가 전체 세대원카드 개수와 동일할 때 if는 true
        if (this.isChildValidationResult && this.currentChildValidationCount === this.totalChildValidationCount) {
            this.fn_ValidateCustStatusBeforAddHosh()
        }
      },
      /******************************************************************************
      * Function명 : fn_Confirm
      * 설명       : 확인버튼
      ******************************************************************************/
      fn_Confirm (){
        if(this.fn_ValidateBeforeNext()){
          let lv_ReturnVal = {
            existHoshInfoListInqrVO: this.existHoshInfoListInqrVO,
            newHoshInfoListInqrVO : this.newHoshInfoListInqrVO

          }
          this.$emit('onPopupConfirm', lv_ReturnVal)
        }
      },
      /******************************************************************************
      * Function명 : fn_ValidateBeforeNext
      * 설명       : 확인버튼 클릭 시, 세대원 validator 체크 후 세대원 컴포넌트 값 유효성 체크
      ******************************************************************************/
      fn_ValidateBeforeNext () {
        const lv_Vm = this
        let lv_RetVal = true
        let targetItem = null
        let Msg = ''
        let index = 0

        if (this.existHoshInfoListInqrVO.length > 0) { // 기존고객
          let lv_existCnt = this.existHoshInfoListInqrVO.length
          for (let i = 0; i < lv_existCnt; i++) {
            if (this.existHoshInfoListInqrVO[i].infoPrcusCnsntTypCd !== '19') { // 필컨포함 선택 시
              if (this.existHoshInfoListInqrVO[i].chkKnbResult === false) {
                Msg = this.existHoshInfoListInqrVO[i].custNm + ' 님은 유효하지 않은 주민등록번호입니다.'
                index = i
                lv_RetVal = false
              } else if (this.existHoshInfoListInqrVO[i].chnlCustScCd === '02' && this.existHoshInfoListInqrVO[i].chkKnbResult === undefined) { // 임시고객 주민번호 뒷자리 입력 안한경우
                Msg = this.existHoshInfoListInqrVO[i].custNm + ' 님의 주민등록번호 뒷자리를 확인하세요.'
                index = i
                lv_RetVal = false
              }
            }
          } // for i
          if (!lv_RetVal) {
            targetItem = this.$refs.mspcm016dExist[index]
          }
        }

        if (this.newHoshInfoListInqrVO.length > 0) { // 신규고객
          let lv_newCnt = this.newHoshInfoListInqrVO.length
          for (let j = 0; j < lv_newCnt; j++) {
            if (this.newHoshInfoListInqrVO[j].chkKnbResult === false) {
              Msg = this.newHoshInfoListInqrVO[j].custNm + ' 님은 유효하지 않은 주민등록번호입니다.'
              index = j
              lv_RetVal = false
            } else if (this.newHoshInfoListInqrVO[j].searchFlag === undefined || this.newHoshInfoListInqrVO[j].searchFlag === '') {
              Msg = this.newHoshInfoListInqrVO[j].custNm + ' 님의 고객카드조회 후 진행 가능합니다.'
              index = j
              lv_RetVal = false
            } else if (this.newHoshInfoListInqrVO[j].isDupCust !== undefined && this.newHoshInfoListInqrVO[j].isDupCust === true) {
              Msg = '중복된 고객카드조회 삭제 후 진행 가능합니다.'
              index = j
              lv_RetVal = false
            }
          } // for j
          if (!lv_RetVal) {
            targetItem = this.$refs.mspcm016dNew[index]
          }
        }

        if (!_.isEmpty(targetItem)) {
          targetItem.$refs['listItem'].isExpand = true
          targetItem.fn_ChildFocusOut() // 입력값 체크후, 고객카드 조회
        } else {
          if (Msg !== '') {
            let alertProps = {
              msg: Msg,
              closeBtn: false,
              preventTouchClose: false,
            }
  
            this.$cmUtil.fn_OpenAlert(lv_Vm, alertProps)
            // this.getStore('confirm').dispatch('ADD', Msg)
          }
        }
        return lv_RetVal
      },
      /******************************************************************************
      * Function명 : fn_ValidationChild
      * 설명       : 세대원 카드 유효성 체크 - 오류 발생시 해당 component로 포커스 이동
      ******************************************************************************/
      fn_ValidationChild () {
        this.isChildValidationResult = true // 세대원 카드 유효성 체크 다시 초기화 함
        this.currentChildValidationCount = 0
        this.totalChildValidationCount = 0

        this.totalChildValidationCount = (this.newHoshInfoListInqrVO.length + this.existHoshInfoListInqrVO.length)
        let lv_LengthNew = this.newHoshInfoListInqrVO.length
        let lv_LengthExist = this.existHoshInfoListInqrVO.length

        if (lv_LengthNew > 0 || lv_LengthExist > 0) {
          if (lv_LengthExist > 0) { // 기존 세대원 validation 체크
            for (var i = lv_LengthExist; i > 0; i--) {
              var lv_Indx = i - 1
              if(this.existHoshInfoListInqrVO[lv_Indx].custNm === '' || typeof this.existHoshInfoListInqrVO[lv_Indx].custNm === 'undefined' ){
                this.getStore('confirm').dispatch('ADD', '기존 세대원 이름을 확인해주세요.')
                return false
              }else if(this.existHoshInfoListInqrVO[lv_Indx].knbFrno === '' || typeof this.existHoshInfoListInqrVO[lv_Indx].knbFrno === 'undefined'){
                this.getStore('confirm').dispatch('ADD', '기존 세대원 주민번호을 확인해주세요.')
                return false
              }

              if(this.infoPrcusCnsntTypCd !== '19' && this.existHoshInfoListInqrVO[lv_Indx].chnlCustScCd === '02'){
                let lv_KnbBkno = ''
                
                if (this.$commonUtil.isMobile()) {
                  lv_KnbBkno = this.existHoshInfoListInqrVO[lv_Indx].knbBknoCipher
                } else {
                  lv_KnbBkno = this.existHoshInfoListInqrVO[lv_Indx].knbBkno
                }

                //필컨
                if(this.$bizUtil.isEmpty(lv_KnbBkno)){
                  //생년월일&성별
                  this.getStore('confirm').dispatch('ADD', '기존 세대원 주민등록번호 뒷자리는 필수입력 항목입니다.')
                  return false
                }
              }
            }//for end
          }

          if (lv_LengthNew > 0) { // 신규 세대원 validation 체크
            for (var i = lv_LengthNew; i > 0; i--) {
              var lv_Indx = i - 1
              if(this.newHoshInfoListInqrVO[lv_Indx].custNm === '' || typeof this.newHoshInfoListInqrVO[lv_Indx].custNm === 'undefined' ){
                this.getStore('confirm').dispatch('ADD', '새로운 세대원 이름을 확인해주세요.')
                return false
              }else if(this.newHoshInfoListInqrVO[lv_Indx].knbFrno === '' || typeof this.newHoshInfoListInqrVO[lv_Indx].knbFrno === 'undefined'){
                this.getStore('confirm').dispatch('ADD', '새로운 세대원 주민번호을 확인해주세요!')
                return false
              }

              if(this.infoPrcusCnsntTypCd !== '19'){
                //필컨
                let lv_KnbBkno = ''
                
                if (this.$commonUtil.isMobile()) {
                  lv_KnbBkno = this.newHoshInfoListInqrVO[lv_Indx].knbBknoCipher
                } else {
                  lv_KnbBkno = this.newHoshInfoListInqrVO[lv_Indx].knbBkno
                }

                if( lv_KnbBkno === '' || typeof lv_KnbBkno === 'undefined'){
                  //생년월일&성별
                  this.getStore('confirm').dispatch('ADD', '새로운 세대원 주민등록번호 뒷자리는 필수입력 항목입니다.')
                  return false
                }
              }else{
                //마컨만
                if( this.newHoshInfoListInqrVO[lv_Indx].knbBkFstNo === '' || typeof this.newHoshInfoListInqrVO[lv_Indx].knbBkFstNo === 'undefined' ){
                  //성별
                  this.getStore('confirm').dispatch('ADD', '새로운 세대원 주민등록번호 뒷자리를 확인해주세요..')
                  return false
                }
              }
            }
          }
          this.fn_ValidateCustStatusBeforAddHosh()
        } else {
           //아무것도 없을 때 
            this.fn_ValidateCustStatusBeforAddHosh()
        }
      },
      /******************************************************************************
      * Function명 : fn_ValidateCustStatusBeforAddHosh
      * 설명       : 주고객이 세대원을 추가할 수 있는지 여부 체크 - 주고객관계코드 01, 02외 고객, 14세 미만 고객은 세대원 추가 불가
      ******************************************************************************/
      fn_ValidateCustStatusBeforAddHosh () {
        let lv_Msg = ''
        let lv_HoshCount = this.existHoshInfoListInqrVO.length + this.newHoshInfoListInqrVO.length // 세대원 총 count
        if (lv_HoshCount === 20) {
          lv_Msg = this.$t('cm')['ECMC060'] // '세대추가는 최대 20명까지 가능합니다.'
        }

        if (lv_Msg === '') {
          if (this.custCardVO.isKnbChanged === true || this.searchRsltMsg === '') { // [고객카드조회] 후 세대원 추가 가능
            lv_Msg = '고객카드조회 후 진행 가능합니다.'
          } else if (this.custCardVO.isKnbChanged === false && this.searchRsltMsg === '기존고객') { // 기존고객일 경우 주고객관계코드(01, 02외 고객) 체크
            if (this.custCardVO.mnCustRltnCd !== '01' && this.custCardVO.mnCustRltnCd !== '02') {
              lv_Msg = '주고객관계가 본인 또는 배우자인 경우에만 세대원을 추가할 수 있습니다.'
            }
          }
        }
        if (lv_Msg === '') {
          this.fn_AddHosh()
        } else {
          this.getStore('confirm').dispatch('ADD', lv_Msg)
        }
      },
      /******************************************************************************
      * Function명 : fn_AddHosh
      * 설명       : 세대원 추가 버튼 클릭 시 호출
      ******************************************************************************/
      fn_AddHosh () {
        var lv_TmpNewHoshInfoAllCnt = this.$bizUtil.isEmpty(this.newHoshInfoListInqrVO) ? 0 : this.newHoshInfoListInqrVO.length // 신규세대원 전체수
        this.newChannelId = lv_TmpNewHoshInfoAllCnt
        let lv_TmpChannelId = (++this.newChannelId).toString() // 화면에서만 사용하는 임시채널 고객 ID부여
        let lv_TmpHoshInfoVO = Object.assign({}, this.hoshInfoVO) // hoshInfoVO 객체 복사
        lv_TmpHoshInfoVO.chnlCustId = lv_TmpChannelId
        lv_TmpHoshInfoVO.customerType = 'new' // 주고객이 기존인지 신규인지 여부 값 (exist or new)
        lv_TmpHoshInfoVO.selcAgreeTypeMcnst = this.selcAgreeTypeMcnst // 주고객 필컨 동의여부
        lv_TmpHoshInfoVO.selcAgreeTypeMkt = this.selcAgreeTypeMkt // 주고객 마케팅 동의 여부
        lv_TmpHoshInfoVO.infoPrcusCnsntTypCd = this.infoPrcusCnsntTypCd // 주고객 정보활용동의 코드

        // 기존 고객일 경우 태아 추가 가능 여부 확인 필드 값 세팅하기
        if (this.custCardVO.customerType === 'exist') {
          let lv_HoshInfoListInqrVO = this.existHoshInfoListInqrVO.filter(
            (value) => {
              if (value.custNm === '태아' && value.knbFrno === '000000') {
                return value
              }
            }
          )

          if (lv_HoshInfoListInqrVO.length > 0) { // 이미 태아가 있는 경우 태아 추가 불가능하다는 필드의 값 추가
            lv_TmpHoshInfoVO.isPossibleFetus = false
          }

          // 신규 추가 세대원 중에 태아가 있는 경우 - 중복으로 입력 막기 위해 태아 추가 불가능하다는 필드의 값 추가
          lv_HoshInfoListInqrVO = this.newHoshInfoListInqrVO.filter(
            (value) => {
              if (value.custNm === '태아' && value.knbFrno === '000000') {
                return value
              }
            }
          )

          if (lv_HoshInfoListInqrVO.length > 0) { // 이미 태아가 있는 경우 태아 추가 불가능하다는 필드의 값 추가
            lv_TmpHoshInfoVO.isPossibleFetus = false
            lv_TmpChannelId = (++this.newChannelId).toString() // 만약, 신규 세대원에 태아가 있는 경우 '이전' 버튼 클릭 후 세대 추가시 임시채널고객ID가 중복됨. 태아가 있는 경우 count 한번 더 추가함.
            lv_TmpHoshInfoVO.chnlCustId = lv_TmpChannelId // 추가된 임시채널 고객ID를 채널고객ID에 매핑함.
          }
        }

        this.newHoshInfoListInqrVO.push(lv_TmpHoshInfoVO) // 신규 세대 고객카드 리스트에 추가
        this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
          //this.$refs.scroll.$el.scrollTop = this.$refs.mspcm016dNew[this.newHoshInfoListInqrVO.length - 1].$el.offsetTop - 20 // 해당 위치로 스크롤 이동
          this.$refs.mspcm016dNew[this.newHoshInfoListInqrVO.length - 1].$refs.listItem.expand() //세대원추가시 펼침 처리
        })
      },
      /******************************************************************************
      * Function명 : fn_DelExistHosh
      * 설명       : 기존 세대원 삭제 버튼 클릭 시 호출 - 2021.07.06 마케팅P 이상인 프로 요청
      ******************************************************************************/
      fn_DelExistHosh (param) {
        let delIdx = -1 // 초기값 설정
        for (let i = 0, len = this.existHoshInfoListInqrVO.length; i < len; i++) { // 삭제하고자 하는 세대원 카드 index 찾기
          if (this.existHoshInfoListInqrVO[i].chnlCustId === param) {
            delIdx = i
            break
          }
        }

        if (delIdx !== -1) {
          this.$delete(this.existHoshInfoListInqrVO, delIdx)
          delIdx = -1
        }
      },
      /******************************************************************************
      * Function명 : fn_ClosePopup
      * 설명       : 세대원 추가 팝업 닫기
      ******************************************************************************/
      fn_ClosePopup () {
        this.$emit('onPopupCancel')
      },
      /******************************************************************************
      * Function명 : fn_ToggleFloating
      * 설명       : 셀렉트 bottomModal 토글 이벤트
      ******************************************************************************/
      fn_ToggleFloating(v) {
        this.bottomSelectShow = v
      },
      /******************************************************************************
      * Function명 : fn_CntryInfoPop
      * 설명       : 국가정보 입력 권유 컨펌 
      *              -Open(팝업 열기), Add(외국인 등록증 진위확인 화면 이동), Close(창닫기)
      ******************************************************************************/
      fn_CntryInfoPop (param, evt) {
        switch(param){
          case 'Open':
            this.currentTar = evt
            this.$refs.bottomSheetCntryInfo.open()
            break
          case 'Add':
            this.$refs.bottomSheetCntryInfo.close()
            this.currentTar.fn_BtnCntryCodeClick()
            break
          case 'Close':
            this.$refs.bottomSheetCntryInfo.close()
            break
        }
      }
    }//methods end
  };
</script>
<style scoped>
</style>