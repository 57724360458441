/*
 * 업무구분: 고객
 * 화 면 명: MSPCM021P
 * 화면설명: 고객 중복조회
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.23
 * 작 성 자: 이태훈
 */
<template>
  <ur-page-container title="필수정보 추가" :show-title="true" type="popup" @before-close="fn_OnBeforeClose">
    <ur-box-container direction="column" alignV="start">
      <mo-validate-watcher ref="vWatcher">

        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-input-box mb45">
            <span class="ns-input-txt mb30">필수컨설팅동의를 위해 {{titleNm}} 뒷자리만 추가로 입력해주세요.</span>
            <span class="ns-title txtGray">이름</span>
            <!-- 이름 -->
            <mo-text-field v-model="custCardVO.custNm" class="form-input-name" readonly underline placeholder="이름를 입력해주세요." :rules="CheckCustNmRule"/>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-input-box mb45">
            <span class="ns-title txtGray" v-if="isForeigner">외국인등록번호</span>
            <span class="ns-title txtGray" v-else>주민등록번호</span>
            <div class="verify-birth ns-style2">
              <!-- 외국인등록번호 앞자리-->
              <mo-text-field v-if="isForeigner" v-model="custCardVO.knbFrno" readonly underline class="birth-input form-input-name" placeholder="외국인등록번호" />
              <!-- 주민번호 앞자리-->
              <mo-text-field v-else-if="!isForeigner" v-model="custCardVO.knbFrno" underline readonly class="birth-input form-input-name" placeholder="생년월일"/>
              <span>-</span>
              <!-- 외국인등록번호 뒷자리 7자리-->
              <mo-text-field ref="error" v-if="isForeigner && !isMobile" v-model="fn_KnbBkno" :rules="targetRule" underline class="birth-input2 form-input-name" placeholder="0"/>              
              <!-- 주민번호 뒷자리-->
              <mo-text-field ref="error" v-else-if="!isForeigner && !isMobile" v-model="fn_KnbBkno" :rules="targetRule" underline class="birth-input2 form-input-name" placeholder="0"/>
              <m-trans-key-input ref="knbBkno" v-else v-model="fn_KnbBkno"  :type="'numberMax7'" :start="'1'" :end="'-1'" @mask-type="fn_getMaskType" class="birth-input3"/>
            </div>
          </ur-box-container>

          <!-- 종료 안내 bottomSheet -->
          <mo-bottom-sheet ref="backCnfrm" class="ns-bottom-sheet ns-style3">
            <div>
            <p class="cancel-ment">
              고객동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 고객정보는 저장되지 않으며, 고객동의는 완료되지 않습니다.
            </p>
            </div>
            <template v-slot:action>
              <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
                <div class="relative-div">
                  <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseBackCnfrmPop('Cancel')">취소</mo-button>
                  <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CloseBackCnfrmPop('Close')">나가기</mo-button>
                </div>
              </ur-box-container>
            </template>
          </mo-bottom-sheet>

          <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseBackCnfrmPop('Open')">취소</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CheckSsnValidation()">확인</mo-button>
            </div>
          </ur-box-container>
        </ur-box-container>

      </mo-validate-watcher>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM021P',
  // 현재 화면 ID
  screenId: 'MSPCM021P',
  // 컴포넌트 선언
  components: {
    MspBottomSelect,
    MSPBottomAlert
  },
  props: {
    pCustCardVO: {
      type:Object
    },
    pInfoPrcusCnsntTypCd: {
      type:String
    },
    pIsForeigner: {
      type:Boolean
    }
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right', // 우->좌 open popup (defalut: 하->상)
    beforeClose: true // 팝업 헤더 백버튼 이벤트 유무
  },
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      stndKeyList: [],
      title: '고객 중복조회', // 화면명
      isForeigner : false, // 외국인 체크 여부
      isBtnValidCmplt: false, //확인 버튼 활성화 여부
      isBackBtn: false, //backBtn 여부
      titleNm:  this.isForeigner ? '외국인등록번호':'주민번호',
      lv_AlertPop: {}, //모달창
      
      // 주고객 정보 VO
      custCardVO: {
        customerType: 'new', // 주고객이 기존, 신규인지 여부
        custNm: '', // 이름
        custNmOrigin: '', // 조회된 실제 고객명
        custNmPrev: '', // 원본 이름(이름명 변경시 체크)
        isCustNmChanged: false, // 고객명 변경 여부
        custId: '', // 사용자ID
        chnlCstgrId: '', // 채널그룹ID
        chnlCustId: '', // 채널 고객 ID
        agreeTypeMcnst: false, // 필수컨설팅 체크
        agreeTypeMkt: false, // 마케팅동의 체크
        chnlCustScCd: '', // 채널고객구분코드(01(가망),02(임시),03(본인모집),04(타인모집),05(관심)) - // 주민번호 미확보 코드 -> 01: 확보 / 02: 미확보
        cnsltNo: '', // 컨설턴트번호
        knb: '', // 전체주민번호
        knbBackup: '', // 주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
        knbFrno: '', // 주민번호 앞자리
        knbBkno: '', // 주민번호 뒷자리
        knbBknoBackup: '', // 성별코드+000000 형태 주민번호 뒷자리 보관장소로 쓰임
        knbBknoCipher: '', // 암호화된 주민번호
        knbBknoCipherBackup: '', // 주민번호 뒷자리 백업(암호화 값이 들어올 경우에만 임시 보관장소로 쓰임)
        knbBkFstNo: '', // 인증 시 입력한 주민번호 뒷자리(성별구분코드)
        isKnbChanged: false, // 주민번호 변경 여부
        samScCd: '', // 음력,양력 구분(양력 : "01"  ,  음력 : "02")
        ralDob: '', // 실제생년월일 "19800101"
        knbScCd: '', // ZPER01 - 내국인, ZPER02 - 외국인
        jobpNm: '', // 직장명
        deptNm: '', // 부서명
        jobpsCdNm: '', // 직위명
        addrStdztYn: '', // 표준화 주소 여부
        stdztAddrYn: '',
        homFrnoPstcd: '', // 자택 우편번호
        homAddr: '', // 자택 주소
        homDtlad: '', //
        bldgNo01: '', //
        adtnAddr01: '', //
        homAddrScCd: '', // 자택주소구분코드(1:지번주소, 2:도로명주소,3:국외주소)
        homAddrRefnCd: '', // 자택주소정제코드(01: 정제주소, 02:입력주소)
        homAddrStatCd: '', // 자택주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
        jobpAddr: '', // 직장 주소
        jobpFrnoPstcd: '', // 직장 우편번호
        jobpDtlad: '', // 직장주소 상세
        bldgNo02: '', //
        adtnAddr02: '', //
        jobpAddrScCd: '', // 직장주소구분코드(1:지번주소, 2:도로명주소,3:국외주소)
        jobpAddrRefnCd: '', // 직장주소정제코드(01: 정제주소, 02:입력주소)
        jobpAddrStatCd: '', // 직장주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
        isAddrChanged: false, // 주소 변경 여부
        isCelnoChanged: false,
        celno: '', // 휴대전화 전체
        celnoBackUp: '', // 휴대전화 전체 하이픈추가
        celnoFrno: {}, // 휴대전화 앞자리
        celnoBkno: '', // 휴대전화 뒷자리
        faxNo: '', //
        homTelno: '', //
        jobpTelno: '', //
        isEmailChanged: false,
        emailAddr: '', //
        emailAddrFrno: '', // 이메일 앞자리
        emailAddrBkno: '', // 이메일 뒷자리 @ 다음
        scrapYn: '', // 스크래핑진위확인 여부
        custEngNm: '', // 고객 영문명
        natyCd: '', // 국가코드
        natyNm: '', // 국가코드명
        psprtNo: '', // 여권번호
        dmclScCd: '', // 거주지구분코드
        dmclScCdNm: '', // 거주지구분코드명
        foreignPartnerNo: '', // 외국인고객번호
        bpCustEngNm: '', // BP 영문명
        bpPsprtNo: '', // BP 여권번호
        bpNatyCd: '', // BP 국적코드
        bpNatyNm: '', // BP 국적명
        bpDmclScCd: '', // BP 거주지구분코드
        bpDmclScCdNm: '', // BP 거주지구분코드명
        custPtclrMatrCntnt: '', // 고객 특이사항 내용
        mnCustRltnCd: '', // 주고객 관계코드 (01: 본인)
        rlnmCnfRsltCd: '', // 실명확인 결과코드
        chnlCstgrRltnCd: '', // 세대주 - X
        isJobChanged: false,
        jobCd: '', // 직업코드
        jobCdNm: '', // 직업명
        introYmd: '', // 소개일시
        introYmdPrev: '', // 소개일 원본 보관
        custNm01: '', // 소개자 명
        custNm01Prev: '', // 소개자 원본 이름(이름명 변경시 체크)
        intdrChnlCustId: '', // 소개자 채널 고객 ID
        acutnCustYn: 'N', // 취득경로가 지인 인 경우 Y로 세팅, 아닐 경우 N
        aptCustYn: 'N', // 취득경로가 개척인 경우 Y로 세팅, 아닐 경우 N
        rspnDstrtCustYn: 'Y', // 취득경로가 기타인 경우 Y로 세팅, 아닐 경우 N
        coalCustYn: 'N', // 제휴고객 여부일 때 Y, 아닐 경우 N
        mcnstYmd: '', // 필수 컨설팅 만료일
        mktYmd: '', // 마케팅 만료일
        chkMcnstNm: '필수컨설팅',
        chkMktNm: '마케팅',
        unAprvCustYn: 'Y', // 미승인 여부 Y:미승인, N: 승인,
        custApvrList: [], // 주고객 필컨/마케팅 동의 활용 정보 리스트
        isMobile: 'Y', // 모바일 사용여부,
        atcarInsrEntCoCd: '',
        atcarInsrEntCoCdNm: '',
        chldFgr: '',
        cndtrYn: '',
        cnvrSlctYn: '',
        covrAnlyPblYn: '',
        custNm02: '',
        dmalReciCnsntYn: '',
        emailReciCnsntYn: '',
        emailReciRjtYn: '',
        hbyCd: '',
        hbyCdNm: '',
        homPstcdSno: '',
        hosOwnYn: '',
        hoshMnYn: '',
        insrExpYmd: '',
        jobLclsfCd: '',
        jobpBknoPstcd: '',
        jobpPstcdSno: '',
        lifclCd: '',
        mailRcpplScCd: '',
        mailRcpplScCdNm: '',
        margMemrYmd: '',
        margScCd: '',
        margScCdNm: '',
        iCHoshInfoListInqrVO: [], // 세대원 리스트
        tabItemList: [],
        iCCustInfoPrcusCnsntVO: {}, // 정보활용동의 내역 객체
        emailStatFlag: '', // 이메일 주소값 변경 여부 - 변경이 있는 경우 X로 바뀜
        backCount: 0,
        trtSystmPathDtlVal: '', // 처리시스템경로상세값 (EP에서 화면ID로 요청)
        busnTrtPathCd: '', // 업무처리경로코드 (태블릿_사랑온고객관리 : 2220)
        trtSystmPathCd: '', // 처리시스템경로코드 (사랑온 고정 구분값 : 22)
        infoPrcusCnsntTypCd: '', // 정보활용동의유형코드
        infoPrcusCnsntStatCd: '', // 정보활용동의상태코드
        infoPrcusCnsntPathCd: '', // 정보활용동의경로코드 (사랑On미니 )
        mktCnvsMthTelYn: 'N',
        mktCnvsMthSmsYn: 'N',
        mktCnvsMthEmailYn: 'N', // //마케팅권유방법이메일여부
        mktCnvsMthPstYn: 'N', // 마케팅권유방법우편여부
        mktCnsntAvlPrd: '', // 마케팅동의유효기간
        athntHstrId: '', // 카카오톡인증이력ID
        lmsDspchRsltId: '', // LMS발송결과ID
        encKey: ''
      },
      // 고객 조회용 Service IF VO
      custCardRegMdfcVO: {},

      /* Validation */
      CheckCustNmRule: [
        v => !!v || '이름은 필수값 입니다.',
      ],
      targetRule: [
        (v) => v.length === 7 || this.titleNm + ' 뒷자리를 정확히 입력해 주세요'
      ],
      
      // BottomPop close 시점 처리 위한 Flg
      closeClickConfirmYn:false,
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    fn_KnbBkno: { // 주민번호 뒷자리 변경시 호출 - 전체주민번호변수(custCardVO.knb)에 변경 값 처리
      get: function () {
        let lv_KnbBkno = ''
        
        if (this.$commonUtil.isMobile()) {
          lv_KnbBkno = this.custCardVO.knbBknoCipher
        } else {
          lv_KnbBkno = this.custCardVO.knbBkno
        }
        return lv_KnbBkno
      },
      set: function (data) {
        if (this.$commonUtil.isMobile()) {
          this.custCardVO.knbBknoCipher = data
          // knbBknoCipher는 주민번호 암호화 입력값 외에 화면에서 보여지는 마스킹 값도 같이 사용하는 필드임. 이에따라 암호화 입력값은 별도 보관 처리 후
          // 저장시점에 knbBknoCipherBackup를 knbBknoCipher에 다시 저장하도록 함.
          if (data !== null && data.length > 7) {
            this.custCardVO.knbBknoCipherBackup = data
          }
        } else {
          this.custCardVO.knbBkno = data
        }
        this.custCardVO.knb = this.custCardVO.knbFrno + data

        //주민번호(외국인등록번호) 뒷자리 입력이 있으면 확인버튼 활성화 여부 체크
        if(data === null || typeof data === 'undedfined' || data === '' || data.trim().length === 0){
          this.isBtnValidCmplt = false
        }else{
          this.isBtnValidCmplt = true
        }
      }
    },

  },
  mounted () {
    // 2024.05.23 App 이원환 문제로 주석 통합App 배포이후 반영
    if( this.isForeigner && !this.isMobile ){
      this.$refs.error.focus()
    }else if( !this.isForeigner && !this.isMobile ){
      this.$refs.error.focus()
    }else{
      this.$refs.knbBkno.onClick()
    }
  },
  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    //window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked) // listen to Event
    this.custCardVO = this.pCustCardVO
    this.infoPrcusCnsntTypCd = this.pInfoPrcusCnsntTypCd
    this.isForeigner =  this.pIsForeigner

    if (this.$commonUtil.isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  },
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_InitializeValue
    * 설명       : 화면 매핑 custCardVO 객체를 초기화 함
    ******************************************************************************/
    fn_InitializeValue () {
      this.custCardRegMdfcVO = null
      this.custCardRegMdfcVO = {}
    },
    /*********************************************************
     * Function명: fn_isExistCustClick
     * 설명: 고객 정보 조회
     * Params: N/A
     * Return: custCardVO, custCardRegMdfcVO
     *********************************************************/
    fn_isExistCustClick () {
      this.fn_InitializeValue() //vo 초기화 셋팅
      let lv_Vm = this
      let pParams = {}
      let lv_UserInfo = lv_Vm.getStore('userInfo').getters.getUserInfo // 사용자 정보
      // 핸드폰번호 '-' 추가
      let lv_Celno = lv_Vm.custCardVO.celno.trim()
      lv_Vm.custCardVO.celno = lv_Vm.fn_FullPhoneDashFormat(lv_Celno, lv_Vm.custCardVO.celnoFrno, lv_Vm.custCardVO.celnoBkno)

      pParams =
                {
                  prcsfCd: '01',
                  custNm: lv_Vm.custCardVO.custNm, // 고객명
                  knbFrno: lv_Vm.custCardVO.knbFrno,
                  knbBknoCipher: lv_Vm.custCardVO.knbBknoCipher,
                  // knbBknoCipher: '', // GA테스트용 삭제처리
                  knbBkno: lv_Vm.custCardVO.knbBkno,
                  cnsltNo: lv_UserInfo.userId, // 컨설턴트 번호
                  celno: lv_Vm.custCardVO.celno, // 핸드폰번호 ('-'하이픈포함)
                  infoPrcusCnsntTypCd: lv_Vm.pInfoPrcusCnsntTypCd, // 정보활용동의코드
                  chnlCustId: '' // 신규고객- 채널고객ID (Agent파일ID) 공백으로
                }
      
      // GA 매니저 대행 컨설턴트 변경시
      if(lv_Vm.$cmUtil.fn_GachangeTF()){
        pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
	  
      //매니저가 컨설턴트를 변경했을경우
      lv_Vm.stndKeyList = []
      if(lv_Vm.$cmUtil.fn_GachangeTF()){
        lv_Vm.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: lv_Vm.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
      }

      // let trnstId = 'txTSSCM13S1'
      let trnstId = 'txTSSCM13S2'

      this.post(lv_Vm, pParams, trnstId, 'S').then(function (response) {

          let lv_RusltCustomerType = 'new' // 'new' : 신규, 'exist' : 기존

          if (response.body !== null) {
            lv_Vm.custCardRegMdfcVO = response.body

            if (!lv_Vm.$bizUtil.isEmpty(response.body.iCHoshInfoListInqrVO) &&
              !lv_Vm.$bizUtil.isEmpty(response.body.iCHoshInfoListInqrVO[0]) &&
              response.body.iCHoshInfoListInqrVO[0].chnlCustId !== undefined && 
            response.body.iCHoshInfoListInqrVO[0].chnlCustId !== null && 
            response.body.iCHoshInfoListInqrVO[0].chnlCustId.trim() !== '') 
            {
              lv_Vm.custCardVO.isExistCustYN = 'Y'
              lv_RusltCustomerType = 'exist'
              if (lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].chnlCustScCd === '02') {
                //임시고객일 경우
                //chnlCustId가 있는 경우 기존고객으로 신규설정 주석처리
                //lv_Vm.custCardVO.isExistCustYN = 'N'
                //lv_RusltCustomerType = 'new'
              }

              lv_Vm.$addSnackbar(lv_Vm.custCardVO.custNm + " 님은 '기존고객'으로 확인 되었습니다.")

            } else { // 신규고객
              lv_Vm.custCardVO.isExistCustYN = 'N'
              lv_Vm.$addSnackbar(lv_Vm.custCardVO.custNm + " 님은 '신규고객'으로 확인 되었습니다.")
            }
            let lv_ResultVo = {
                                custCardVO : lv_Vm.custCardVO,
                                custCardRegMdfcVO: lv_Vm.custCardRegMdfcVO,
                                customerType : lv_RusltCustomerType
                              }
            
            lv_Vm.closeClickConfirmYn = true
            lv_Vm.$emit('onCusDupSearchPopup', lv_ResultVo)

          } else { 
            // 조회 결과가 없는 경우
            if (response.msgComm.msgCd === 'EEAC928') {
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('co')['ECOC009']) // '조회하고자 하는 데이터가 없습니다'
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /*********************************************************
     * Function명: fn_IsNextBtnVisible
     * 설명: 다음버튼 보이게 하는 여부 처리 (focus일 경우 false, blur(focus out)일 경우 true)
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_IsNextBtnVisible (val, refNm) {
      if (this.$commonUtil.isMobile()) { // 모바일일 경우만 처리 - text input focus로 안드로이드 키보드 올라올 경우 '다음' 버튼 안보이게 처리
        this.isNextBtnVisible = val
        this.focusRefName = refNm
      }
    },
    /******************************************************************************
    * Function명 : fn_CloseBackCnfrmPop
    * 설명       : 종료동의 안내 
    *             - OPEN(컨펌팝업열기), Cancel(화면유지), Close(동의종료) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_CloseBackCnfrmPop (param) {

      this.closeClickConfirmYn = false
      switch(param){
        case 'Open':
          this.$refs.backCnfrm.open()
          break
        case 'Cancel':
           this.$refs.backCnfrm.close()
          break
        case 'Close':
          this.closeClickConfirmYn = true
          this.$refs.backCnfrm.close()
          this.$emit('onCusDupSearchPopupClose')
          break
      }
      
    },
    /*********************************************************
     * Function명: fn_BackBtnClicked
     * 설명: 스마트폰 back 버튼 클릭 시 호출
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_BackBtnClicked () { // 스마트폰의 back 버튼 선택 시 처리

      // Global 컴펌이 show 상태인지 확인
      // let confirmMsg = this.getStore('confirm')
      // if (confirmMsg.getters.getState.isShow) {
      //   confirmMsg.dispatch('REMOVE') // show 상태이면 닫기
      // } else if (this.isTSSSP130P) { // 주소검색창
      //   this.isTSSSP130P = false
      // } else if (this.isConfirmMove) {
      //   this.isConfirmMove = false
      // } else if (this.$popupManager.getPopupList().length > 0) { // popupList가 1개 이상인 경우
      //   this.$popupManager.removeLastPopup() // 가장 최근 팝업창 close
      // }

      if(isBackBtn){
        this.isBackBtn = false
        this.fn_CloseBackCnfrmPop('Close')
      }else{
        this.isBackBtn = true
        this.fn_CloseBackCnfrmPop('Open')
      }

    },
    /*********************************************************
     * Function명: fn_FullPhoneDashFormat
     * 설명: 핸드폰번호 Dash 처리
     * Params: 전화번호
     * Return: Dash된 전화번호
     *********************************************************/
    fn_FullPhoneDashFormat (param, celnoFrno, celnoBkno) {
      if(!param) return ''

      let lv_Celno = param // - 없는 전화번호
      let lv_CelnoFrno = celnoFrno // 휴대폰번호 앞자리 추출
      
      if (lv_CelnoFrno !== undefined && lv_CelnoFrno.length > 3) { // 휴대폰 앞 자리가 3자리가 아닌, 0130 혹은 0505과 같은 dash 별도 처리
        lv_Celno = lv_CelnoFrno + '-' + this.fn_PhoneDashFormat(celnoBkno)
      } else {
        lv_Celno = this.fn_PhoneDashFormatAll(lv_Celno)
      }

      return lv_Celno
    },
    /*********************************************************
     * 전화번호 형식 format(8자리)
     * 예) 1234-5678
     *
     * value: 전화번호 형식으로 변경할 String
     *********************************************************/
    fn_PhoneDashFormat (value) {
      if (!value) return ''
      value = value.toString().replace(/-/gi, '')

      if (value.length > 8) {
        value = value.substr(0, 8)
        value = this.$commonUtil.insertString(value, 4, '-')
      } else if (value.length === 8) {
        value = this.$commonUtil.insertString(value, 4, '-')
      } else if (value.length > 3) {
        value = this.$commonUtil.insertString(value, 3, '-')
      }
      return value
    },
    /*********************************************************
     * 이통사 포함 전화번호 형식 format(10자리 or 11자리)
     * 예) 010-1234-5678
     *
     * value: 전화번호 형식으로 변경할 String
     *********************************************************/
    fn_PhoneDashFormatAll (value) {
      if (!value) return ''
      value = value.toString().replace(/-/gi, '')

      if (value.length >= 11) {
        value = value.substr(0, 11)
        value = this.$commonUtil.insertString(value, 7, '-')
        value = this.$commonUtil.insertString(value, 3, '-')
      } else if (value.length > 7) {
        value = this.$commonUtil.insertString(value, 6, '-')
        value = this.$commonUtil.insertString(value, 3, '-')
      } else if (value.length > 3) {
        value = this.$commonUtil.insertString(value, 3, '-')
      }
      return value
    },
    /******************************************************************************
    * Function명 : fn_CheckSsnValidation
    * 설명       : 주민번호 유효성 체크
    ******************************************************************************/
    fn_CheckSsnValidation () {
      if (!this.isBtnValidCmplt) { 
        let alertMsg = '주민번호 뒷자리를 입력해주시기 바랍니다.'
        let callbackFnc = this.$refs['knbBkno'].onClick

        this.fn_OpenAlert(alertMsg, callbackFnc)
      }
      
      let lv_TmpValidation = false //주민번호(외국인등록번호) 유무 체크

      if (this.$commonUtil.isMobile()) {
        if (this.custCardVO.knbBknoCipher !== null && this.custCardVO.knbBknoCipher.trim() !== '') {
          lv_TmpValidation = true
        }
      } else {
        if (this.custCardVO.knbBkno !== null && this.custCardVO.knbBkno.trim() !== '') {
          lv_TmpValidation = true
        }
      }
      // 주민번호 앞자리가 입력되었는지, 주민번호 뒷자리가 입력되었는지 확인 후, 유효성 체크
      if (!this.custCardVO.knbFrno !== null && this.custCardVO.knbFrno.trim() !== '' && lv_TmpValidation) {
        this.isLoading = true
        let lv_Vm = this

        let lv_Knb = (lv_Vm.$commonUtil.isMobile()) ? '' : lv_Vm.custCardVO.knb // PC일 경우 전체 평문 주민번호를 넘겨줌. 모바일일 경우 공백처리
        // let lv_Knb = lv_Vm.custCardVO.knb // GA테스트용 삭제처리
        let pParams =
                      {
                        knb: lv_Knb, // 전체 주민번호
                        knbFrno: lv_Vm.custCardVO.knbFrno, // 주민번호 앞번호
                        knbBknoCipher: lv_Vm.custCardVO.knbBknoCipher, // 주민번호 뒷번호
                        // knbBknoCipher: '', // GA테스트용 삭제처리
                        knbScCd: lv_Vm.encKey // ios encrypt key
                      }

        let trnstId = 'txTSSCM10S6'
        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null) {
              if (response.body.ssnYn === 'Y') {
                lv_Vm.custCardVO.knbBackup = response.body.knb

                // 주민번호 유형값 세팅
                let lv_KnbBkno = response.body.knb.substring(6, 7)
                if (lv_KnbBkno === '1' || lv_KnbBkno === '2' || lv_KnbBkno === '3' || lv_KnbBkno === '4') {
                  lv_Vm.custCardVO.knbScCd = 'ZPER01'
                } else if (lv_KnbBkno === '5' || lv_KnbBkno === '6' || lv_KnbBkno === '7' || lv_KnbBkno === '8') {
                  lv_Vm.custCardVO.knbScCd = 'ZPER02'
                } else {
                  lv_Vm.custCardVO.knbScCd = ''
                }
                
                lv_Vm.fn_isExistCustClick()
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      }
      return lv_TmpValidation
    },
    /******************************************************************************
    * Function명: fn_getMaskType
    * 설명: ios에서 넘어온 mtranskey key
    * Params: N/A
    * Return: N/A
    ******************************************************************************/
    fn_getMaskType (param) {
      this.encKey = param
      this.custCardVO.knbBkno = this.encKey
    },
    /******************************************************************************
    * Function명: fn_OnBeforeClose
    * 설명: 헤더 영역 Back Button 분기 처리
    * Params: N/A
    * Return: N/A
    ******************************************************************************/
    fn_OnBeforeClose(close) {
      if(this.closeClickConfirmYn) {
        close()
      } else {
        this.fn_CloseBackCnfrmPop('Open')
      }
    },
    /******************************************************************************
    * Function명: fn_OpenAlert
    * 설명: 알럿 함수
    * Params: N/A
    * Return: N/A
    ******************************************************************************/
    fn_OpenAlert(alertMsg, callbackFnc) {
      const lv_Vm = this

      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "",
            content: alertMsg,
            title_pos_btn: "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              if (typeof(callbackFnc) === 'function') callbackFnc()
            }
          }
        })
    }
  }//methods end
}
</script>
